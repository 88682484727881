import { IPopoverItem } from '@lib/react-components';

import { usePortalConfiguration } from 'features/Portal';
import { useRootModelPath } from 'features/Root/model';
import { useUserModelRedirectToParam } from 'features/Users/model';
import { i18n } from 'i18n';
import { REGISTRATION_ROUTES_TYPES } from 'lib/constants/registration';
import { ROUTE_PATHS } from 'routes/constants';

export const useUnauthorizedHeaderActions = (): IPopoverItem[] => {
  const { makeGoToPath } = useRootModelPath();
  const portalConfiguration = usePortalConfiguration();
  const redirectParam = useUserModelRedirectToParam();

  return [
    {
      title: i18n.t('general.buttons.login'),
      onClickHandler: makeGoToPath({ path: ROUTE_PATHS.loginByPortal, search: redirectParam }),
    },
    {
      title: i18n.t('general.buttons.registerAsPatient'),
      onClickHandler: makeGoToPath({
        path: ROUTE_PATHS.registrationByTypeAndPortal,
        pathParams: { type: REGISTRATION_ROUTES_TYPES.PATIENT },
        search: redirectParam,
      }),
      disable: !portalConfiguration?.isPatientSelfRegistrationEnabled,
    },
  ];
};
