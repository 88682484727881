import { WebToNativeActions } from '@lib/features-bll';

import { SearchParams, useRootModelPath } from 'features/Root/model';
import { REGISTRATION_ROUTES_TYPES } from 'lib/constants/registration';
import { platformBasedAction, createNativeAction } from 'lib/helpers/platform';
import { ROUTE_PATHS } from 'routes/constants';

type UseRegistrationModelRegistrationLinksReturnParams = {
  type: REGISTRATION_ROUTES_TYPES;
  redirectForApp?: string;
} & SearchParams;

type UseRegistrationModelRegistrationLinksReturn = (
  params: UseRegistrationModelRegistrationLinksReturnParams
) => VoidFunction;
type UseRegistrationModelRegistrationLinks = () => UseRegistrationModelRegistrationLinksReturn;

export const useRegistrationModelRegistrationLinks: UseRegistrationModelRegistrationLinks = () => {
  const { makeGoToPath, getPath } = useRootModelPath();

  return ({ type, redirectForApp, ...otherParams }) =>
    () =>
      platformBasedAction({
        webAction: makeGoToPath({
          path: ROUTE_PATHS.registrationByTypeAndPortal,
          pathParams: { type },
          ...otherParams,
        }),
        nativeAction: createNativeAction(WebToNativeActions.signUp, {
          redirect: redirectForApp ? getPath({ path: redirectForApp, ...otherParams }) : undefined,
        }),
      });
};
