import { i18n } from 'i18n';

interface IUseDashboardWelcomeTitleProps {
  fullName: string;
  gender: string;
  greetingTitle?: string;
}

interface IUseDashboardWelcomeTitleReturn {
  welcomeBackTitle: string;
}

export const useDashboardWelcomeTitle = ({
  fullName,
  greetingTitle = '',
  gender,
}: IUseDashboardWelcomeTitleProps): IUseDashboardWelcomeTitleReturn => ({
  welcomeBackTitle: i18n.t('pages.dashboard.welcomeBack', {
    context: gender.toLocaleLowerCase(),
    greetingTitle,
    name: fullName,
  }),
});
