import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type UpdateNoticeUnreadListVariables = Types.Exact<{ [key: string]: never; }>;


export type UpdateNoticeUnreadList = { __typename?: 'Query', unreadUpdateNoticeList: Array<{ __typename?: 'UpdateNoticeModel', content: string, createdAt: string, id: string, title: string }> };


export const UpdateNoticeUnreadListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"UpdateNoticeUnreadList"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"unreadUpdateNoticeList"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"content"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useUpdateNoticeUnreadList__
 *
 * To run a query within a React component, call `useUpdateNoticeUnreadList` and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoticeUnreadList` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateNoticeUnreadList({
 *   variables: {
 *   },
 * });
 */
export function useUpdateNoticeUnreadList(baseOptions?: ApolloReactHooks.QueryHookOptions<UpdateNoticeUnreadList, UpdateNoticeUnreadListVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UpdateNoticeUnreadList, UpdateNoticeUnreadListVariables>(UpdateNoticeUnreadListDocument, options);
      }
export function useUpdateNoticeUnreadListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UpdateNoticeUnreadList, UpdateNoticeUnreadListVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UpdateNoticeUnreadList, UpdateNoticeUnreadListVariables>(UpdateNoticeUnreadListDocument, options);
        }
export type UpdateNoticeUnreadListHookResult = ReturnType<typeof useUpdateNoticeUnreadList>;
export type UpdateNoticeUnreadListLazyQueryHookResult = ReturnType<typeof useUpdateNoticeUnreadListLazyQuery>;
export type UpdateNoticeUnreadListQueryResult = Apollo.QueryResult<UpdateNoticeUnreadList, UpdateNoticeUnreadListVariables>;