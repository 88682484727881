import { AddIcon, MaterialSymbolsIcon } from '@lib/icons';
import { defaultBorderRadius, useBreakpoints } from '@lib/react-components';
import { Button, Typography, useTheme } from '@mui/material';

interface IHeaderMobilePlusButtonProps {
  onClick: VoidFunction;
  text?: string;
}

export const HeaderMobilePlusButton = ({ onClick, text }: IHeaderMobilePlusButtonProps): JSX.Element => {
  const theme = useTheme();
  const { isMobileView } = useBreakpoints();

  return (
    <Button
      size="small"
      sx={{
        borderRadius: defaultBorderRadius,
        padding: '9px 13px 9px 8px',
        color: theme.palette.white.main,
        ...(isMobileView && {
          minWidth: 0,
          padding: '8px',
          borderRadius: '50%',
        }),
      }}
      onClick={onClick}
    >
      <MaterialSymbolsIcon icon={<AddIcon fill={theme.palette.white.main} fontWeight="300" />} />
      {text && <Typography variant="h10">{text}</Typography>}
    </Button>
  );
};
