import { getDoctorUserTitle } from '@lib/features-bll';
import { Loader } from '@lib/react-components';
import { FullNameFormat, getUserFullName } from '@lib/utils';
import { Typography } from '@mui/material';

import { Gender } from '__generated__/types';
import { dashboardModel } from 'features/Dashboard/model';
import { useUserModelStore } from 'features/Users/model';

interface IDashboardUiWelcomeProps {
  spaced?: boolean;
}

export const DashboardUiWelcome = ({ spaced = true }: IDashboardUiWelcomeProps): JSX.Element => {
  const { userInfo, activeDoctor, activeNonDoctor } = useUserModelStore();
  const gender = userInfo?.gender ?? Gender.UNKNOWN;

  const fullName = getUserFullName(userInfo, FullNameFormat.FIRST_NAME_LAST_NAME);
  const userTitleCode = activeDoctor?.doctor.titleCode ?? activeNonDoctor?.nonDoctor.titleCode;
  const { welcomeBackTitle } = dashboardModel.useDashboardWelcomeTitle({
    fullName,
    gender,
    greetingTitle: getDoctorUserTitle(userTitleCode, gender),
  });

  return (
    <Loader loading={!(userInfo && userInfo.gender && (activeDoctor || activeNonDoctor))}>
      <Typography
        align="center"
        variant="h5"
        paragraph
        data-cy="welcome message"
        sx={{
          margin: spaced ? '16px 0' : 0,
        }}
      >
        {welcomeBackTitle}
      </Typography>
    </Loader>
  );
};
