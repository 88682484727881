import qs from 'qs';
import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { UseGlobalSearchModelStore } from './useGlobalSearchModelStore';

import {
  globalSearchDetailsModel,
  useGlobalSearchDetailsTabChangeHandler,
  useGlobalSearchDetailsTabsConfiguration,
  useGlobalSearchModelStore,
} from 'features/GlobalSearchDetails';
import { useRootModelPath } from 'features/Root/model';
import { ROUTE_PATHS } from 'routes/constants';

interface IUseGlobalSearchReturn {
  onSearch: (newSearch: string, hitType?: string) => void;
  globalSearch: ReturnType<UseGlobalSearchModelStore>['globalSearch'];
  setGlobalSearch: ReturnType<UseGlobalSearchModelStore>['setGlobalSearch'];
  allowEmptySearch: boolean;
}

export const useGlobalSearch = (): IUseGlobalSearchReturn => {
  const { getPath } = useRootModelPath();
  const { globalSearch, setGlobalSearch } = useGlobalSearchModelStore();
  const { search } = globalSearchDetailsModel.useGlobalSearchFilters();
  const handleSearchTabChange = useGlobalSearchDetailsTabChangeHandler();
  const { priorityTab } = useGlobalSearchDetailsTabsConfiguration();
  const { pathname } = useLocation();

  const onSearch = (newSearch: string, hitType?: string): void => {
    handleSearchTabChange(
      priorityTab,
      qs.stringify(
        {
          search: newSearch || null,
          hitType: hitType || null,
        },
        { addQueryPrefix: true, skipNulls: true }
      )
    );
  };

  useEffect(() => {
    setGlobalSearch({ search, source: 'web' });
  }, [search]);

  useEffect(() => {
    if (globalSearch.source === 'app') {
      onSearch(globalSearch.search);
    }
  }, [globalSearch.search]);

  return {
    globalSearch,
    setGlobalSearch,
    allowEmptySearch: !!matchPath(pathname, getPath({ path: ROUTE_PATHS.search })),
    onSearch,
  };
};
