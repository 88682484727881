import { useRootModelInfoRedirectByState, useRootModelPath } from 'features/Root/model';
import { ROUTE_PATHS } from 'routes/constants';

export const useRootModelRedirectByState = (): string | undefined => {
  const { getPath } = useRootModelPath();
  const infoRedirect = useRootModelInfoRedirectByState();

  if (infoRedirect) {
    return getPath({ path: ROUTE_PATHS.dashboard });
  }

  return undefined;
};
