import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import { AffiliationForDoctorModel } from './AffiliationForDoctorModel.fragment';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type DoctorAffiliationsVariables = Types.Exact<{
  filterBy?: Types.InputMaybe<Types.DoctorAffiliationListFilterByArgs>;
  pagination?: Types.InputMaybe<Types.PaginationModel>;
}>;


export type DoctorAffiliations = { __typename?: 'Query', doctorAffiliations: { __typename?: 'PaginatedDoctorAffiliationModelResponse', totalCount: number, items: Array<{ __typename?: 'AffiliationForDoctorModel', affiliationId: string, doctorPortalUserId: string, name: string, startDate: string, endDate: string, isExpired: boolean, joinStatus: Types.AffiliationDoctorJoinStatus, services: Array<{ __typename?: 'ServiceModel', patientBookingRequiresRequestProposal: boolean, doctorCanCreateRequestProposal: boolean, isEnabled: boolean, baseServiceType: Types.ServiceType, title: string, description: string, id: string, price: number, durationMinutes?: number | null, providedWithinDays?: number | null, giftType: Types.ServiceGiftType, bookingProviderExtension: { __typename?: 'AffiliationBookingProviderExtensionModel', bookingType: Types.ServiceBookingType } | { __typename?: 'ClinicBookingProviderExtensionModel', bookingType: Types.ServiceBookingType } | { __typename?: 'DoctorBookingProviderExtensionModel', bookingType: Types.ServiceBookingType }, addons: Array<{ __typename?: 'ServiceAddonModel', id: string, type: Types.ServiceAddonType, price: number, providedWithinDays?: number | null }> }> }> } };


export const DoctorAffiliationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"DoctorAffiliations"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filterBy"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"DoctorAffiliationListFilterByArgs"}},"defaultValue":{"kind":"NullValue"}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"pagination"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"PaginationModel"}},"defaultValue":{"kind":"NullValue"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"doctorAffiliations"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filterBy"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filterBy"}}},{"kind":"Argument","name":{"kind":"Name","value":"pagination"},"value":{"kind":"Variable","name":{"kind":"Name","value":"pagination"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"items"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AffiliationForDoctorModel"}}]}},{"kind":"Field","name":{"kind":"Name","value":"totalCount"}}]}}]}},...AffiliationForDoctorModel.definitions]} as unknown as DocumentNode;

/**
 * __useDoctorAffiliations__
 *
 * To run a query within a React component, call `useDoctorAffiliations` and pass it any options that fit your needs.
 * When your component renders, `useDoctorAffiliations` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorAffiliations({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useDoctorAffiliations(baseOptions?: ApolloReactHooks.QueryHookOptions<DoctorAffiliations, DoctorAffiliationsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<DoctorAffiliations, DoctorAffiliationsVariables>(DoctorAffiliationsDocument, options);
      }
export function useDoctorAffiliationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DoctorAffiliations, DoctorAffiliationsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<DoctorAffiliations, DoctorAffiliationsVariables>(DoctorAffiliationsDocument, options);
        }
export type DoctorAffiliationsHookResult = ReturnType<typeof useDoctorAffiliations>;
export type DoctorAffiliationsLazyQueryHookResult = ReturnType<typeof useDoctorAffiliationsLazyQuery>;
export type DoctorAffiliationsQueryResult = Apollo.QueryResult<DoctorAffiliations, DoctorAffiliationsVariables>;