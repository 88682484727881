import { BREAK_POINTS, fontWeight, getDefaultBoxShadow } from '@lib/react-components';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  tableContentWrapper: {
    boxShadow: getDefaultBoxShadow(theme.palette.shadow.main),
    background: theme.palette.white.main,
    borderRadius: 10,
    marginTop: 16,
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
  },

  leftAlignedForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    margin: '0 auto',
  },

  formWithSections: {
    borderRadius: 10,
    padding: '25px 45px',
    width: '100%',
    alignItems: 'unset',
    backgroundColor: theme.palette.white.main,
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      maxWidth: '100%',
      padding: '32px 16px',
      boxShadow: 'initial',
    },
  },

  formWithMainSection: {
    '&.MuiBox-root': {
      padding: '50px 45px',
    },
  },

  formTitle: {
    textTransform: 'uppercase',
  },

  formSubtitle: {
    fontSize: 18,
  },

  formField: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: 'calc(100% - 20px)',
    maxWidth: 450,
    marginBottom: 10,
    '& .MuiInputAdornment-positionEnd .MuiIconButton-root': {
      color: theme.palette.secondary.main,
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: 'unset',
      width: '100%',
    },
  },

  formFieldFullLength: {
    maxWidth: '100%',
  },

  osrFormField: {
    '& .MuiInputBase-input': {
      padding: '9px 0 7px',
    },
  },

  fieldInput: {
    width: '100%',
  },

  formFieldCheckbox: {
    display: 'flex',
    alignItems: 'flex-start',
    width: 'calc(100% - 20px)',
    marginBottom: 10,
    flexDirection: 'row',
    '& .Mui-checked .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
      stroke: theme.palette.white.main,
    },
    '& .Mui-disabled .MuiSvgIcon-root': {
      color: theme.palette.aux.disabled,
      pointerEvents: 'none',
    },
    '& .Mui-disabled + label': {
      color: theme.palette.aux.disabled,
      cursor: 'default',
    },
    '& .MuiSvgIcon-root': {
      fontSize: 20,
    },
  },

  formFieldLabel: {
    fontSize: 14,
    lineHeight: '140%',
    marginBottom: 10,
    marginTop: 10,
    cursor: 'pointer',
  },

  formFieldText: {
    fontSize: 14,
    lineHeight: '17px',
  },

  submitFormWrapper: {
    display: 'flex',
    width: '100%',
  },

  buttonDefault: {
    width: '100%',
    height: 48,
    fontWeight: fontWeight.semiBold,
    fontSize: 18,
    borderRadius: 10,
    lineHeight: '22px',
    cursor: 'pointer',
    marginTop: 40,
    outline: 0,
  },

  submitFormDefault: {
    color: theme.palette.white.main,
    background: theme.palette.primary.main,
    border: 0,
    '&:hover': {
      background: theme.palette.action.hover,
    },
    '&:visited, &:active': {
      background: theme.palette.primary.main,
    },
    '&:disabled': {
      background: theme.palette.action.disabled,
      pointerEvents: 'none',
    },
  },

  cancelFormDefault: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.generic.dark,
    border: `1px solid ${theme.palette.generic.light}`,
  },

  backendError: {
    color: theme.palette.error.main,
    marginBottom: 20,
  },

  backendErrorBottom: {
    margin: '24px 0',
  },

  inputAttachments: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginBottom: 50,
    '& .MuiFormControl-root': {
      marginBottom: 0,
    },
  },
}));
