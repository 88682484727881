import { useHistory } from 'react-router-dom';

import { useRegistrationModelRegistrationLinks } from 'features/Registration/model';
import { useUserModelRedirectToParam } from 'features/Users/model';
import { REGISTRATION_ROUTES_TYPES } from 'lib/constants/registration';

interface IUseLoginOrRegisterBlockingModalControlsReturn {
  handleHistoryGoBackClick: VoidFunction;
  handleProceedToPatientRegisterClick: VoidFunction;
}

export const useLoginOrRegisterBlockingModalControls = (): IUseLoginOrRegisterBlockingModalControlsReturn => {
  const history = useHistory();
  const redirectParam = useUserModelRedirectToParam();
  const registrationHandler = useRegistrationModelRegistrationLinks();

  const handleHistoryGoBackClick: IUseLoginOrRegisterBlockingModalControlsReturn['handleHistoryGoBackClick'] = () =>
    history.goBack();

  const handleProceedToPatientRegisterClick: IUseLoginOrRegisterBlockingModalControlsReturn['handleProceedToPatientRegisterClick'] =
    registrationHandler({ type: REGISTRATION_ROUTES_TYPES.PATIENT, search: redirectParam });

  return {
    handleHistoryGoBackClick,
    handleProceedToPatientRegisterClick,
  };
};
