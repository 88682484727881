import { WebToNativeActions } from '@lib/features-bll';
import { IModalProps, ModalSize } from '@lib/react-components';
import { useLocation } from 'react-router-dom';

import { usePortalConfiguration } from 'features/Portal';
import { useRootModelPath } from 'features/Root/model';
import { LoginOrRegisterBlockingModalContent } from 'features/Users/components';
import { i18n } from 'i18n';
import { createNativeAction, platformBasedAction } from 'lib/helpers/platform';
import { ROUTE_PATHS } from 'routes/constants';

interface ILoginOrRegisterBlockingModalUnauthorizedModalProps extends Pick<IModalProps, 'onCancel'> {
  handleProceedToPatientRegisterClick: VoidFunction;
}

export const LoginOrRegisterBlockingModalUnauthorizedModal = ({
  handleProceedToPatientRegisterClick,
  onCancel,
}: ILoginOrRegisterBlockingModalUnauthorizedModalProps): JSX.Element => {
  const { makeGoToPath } = useRootModelPath();
  const { pathname, search } = useLocation();
  const portalConfiguration = usePortalConfiguration();

  const handleProceedToPatientLoginClick: VoidFunction = () => {
    const redirectTo = `${pathname}${search}`;

    return platformBasedAction({
      webAction: makeGoToPath({
        path: ROUTE_PATHS.loginByPortal,
        searchObj: { redirectTo },
        skipNulls: true,
      }),
      nativeAction: createNativeAction(WebToNativeActions.login, { redirect: redirectTo }),
    });
  };

  return (
    <LoginOrRegisterBlockingModalContent.ModalWrapper
      onCancel={onCancel}
      size={ModalSize.SM}
      footer={
        <>
          <LoginOrRegisterBlockingModalContent.ButtonPrimary
            onClick={handleProceedToPatientLoginClick}
            label={i18n.t('features.users.loginOrRegisterBlockingModal.button.signIn')}
          />
          <LoginOrRegisterBlockingModalContent.ButtonSecondary
            onClick={handleProceedToPatientRegisterClick}
            label={i18n.t('features.users.loginOrRegisterBlockingModal.button.register')}
            disabled={!portalConfiguration?.isPatientSelfRegistrationEnabled}
          />
        </>
      }
    >
      <LoginOrRegisterBlockingModalContent.Title
        sx={{ marginTop: '24px' }}
        translationPath={
          pathname.includes(ROUTE_PATHS.infoList)
            ? 'features.users.loginOrRegisterBlockingModal.title.nonAuthorizedInfo'
            : 'features.users.loginOrRegisterBlockingModal.title.nonAuthorized'
        }
      />
    </LoginOrRegisterBlockingModalContent.ModalWrapper>
  );
};
