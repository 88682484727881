import { PersistedDataKeys } from '@lib/core';
import {
  chatModel as chatModelBll,
  IClinicChatsItemParsed,
  IOneToOneChatsItemParsed,
  useDashboardApiPortalUserStatsLazyQuery,
} from '@lib/features-bll';
import { InfoRequestIcon, IntramedIcon, ReminderIcon, RequestsIcon, SurveysIcon } from '@lib/icons';
import { useBreakpoints } from '@lib/react-components';
import { iif } from '@lib/utils';
import { useEffect, useImperativeHandle, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { DashboardSubRoutes, DOCTOR_DASHBOARD_TILES_LABELS } from '../config';
import {
  DashboardDoctorSubRoutes,
  DoctorCounters,
  DoctorCountersSubRoutesCounters,
  IDashboardTile,
  IDashboardUrlParams,
  IUseDashboardParams,
} from '../types';

import { useComputeDefaultActiveItem } from './useComputeDefaultActiveItem';
import { useFormatDashboardActiveItemRoute } from './useFormatDashboardActiveItemRoute';

import { ChatOrderByField, ChatType, Maybe, OrderByType } from '__generated__/types';
import { CHATS_LISTING_CONFIGURATION, NUMBER_OF_CHAT_LIST_ROWS } from 'features/Chat/config';
import { useChatModelChatListingFilters, useChatModelChatSortPreference } from 'features/Chat/model';
import { IChatSortProps } from 'features/Chat/types';
import { useDoctorModelSecretaryAssociations } from 'features/Doctor/model';
import { useInfoModelInfoList } from 'features/Info';
import { usePortalConfiguration } from 'features/Portal';
import { useDashboardReminders } from 'features/Reminders/graphql/__generated__/DashboardReminders.query';
import { ReminderTableRow } from 'features/Reminders/ui/RemindersTable/types';
import { useDoctorSurveyList } from 'features/Surveys/hooks';
import { IDoctorSurveyListEntry } from 'features/Surveys/types';
import { useUserModelStore } from 'features/Users/model';
import { PaginatedRequestData } from 'graphql/types';
import { IUsePaginationReturnType } from 'lib/hooks/usePagination.hook';

interface IUseDoctorNonDoctorDashboardReturn {
  dashboardLoading: boolean;
  tiles: IDashboardTile[];
  activeItem: DashboardSubRoutes;
  activeCount: Maybe<number>;
  totalCount: number;
  firstAccess: boolean;
  isPatientSelfRegistrationEnabled: boolean;
  pinnedOneToOneChats: IOneToOneChatsItemParsed[];
  pinnedOneToOneChatsTotalCount: number;
  pinnedOneToOneChatsLoading: boolean;
  pinnedOneToOneChatsSortProps: IChatSortProps;
  isViewBoxPinnedOneToOneChatsVisible: boolean;
  loadMorePinnedOneToOneChats: VoidFunction;
  pinnedClinicChats: IClinicChatsItemParsed[];
  pinnedClinicChatsTotalCount: number;
  pinnedClinicChatsLoading: boolean;
  pinnedClinicChatsSortProps: IChatSortProps;
  loadMorePinnedClinicChats: VoidFunction;
  isViewBoxPinnedClinicChatsVisible: boolean;
  infos: ReturnType<typeof useInfoModelInfoList>['infoItems'];
  infoLoading: boolean;
  infoPaginationProps: ReturnType<typeof useInfoModelInfoList>['paginationProps'];
  infoSortProps: ReturnType<typeof useInfoModelInfoList>['sortProps'];
  surveys?: PaginatedRequestData<IDoctorSurveyListEntry[]>;
  surveysLoading: boolean;
  surveysPagination: IUsePaginationReturnType;
  reminders: Maybe<ReminderTableRow[]>;
  remindersLoading: boolean;
  remindersRefetch: ReturnType<typeof useDashboardReminders>['refetch'];
  compactTiles: boolean;
}

export const useDoctorNonDoctorDashboard = ({
  dashboardRef,
}: IUseDashboardParams): IUseDoctorNonDoctorDashboardReturn => {
  const configuration = usePortalConfiguration();
  const { activeProfile, isPatient, isNonDoctor } = useUserModelStore();

  const { isMobileView } = useBreakpoints();
  const { activeItem } = useParams<IDashboardUrlParams>();
  const { search } = useLocation();
  const rawFirstAccess = new URLSearchParams(search).get('firstAccess') ?? 'false';
  const firstAccess = JSON.parse(rawFirstAccess);

  const [counters, setCounters] = useState<DoctorCounters>({
    [DashboardSubRoutes.INTRAMED]: 0,
    [DashboardSubRoutes.INFO]: 0,
    [DashboardSubRoutes.SURVEYS]: 0,
    [DashboardSubRoutes.REMINDERS]: 0,
    [DashboardSubRoutes.CHATS_ONE_TO_ONE]: 0,
    [DashboardSubRoutes.CHATS_CLINIC]: 0,
  });

  useImperativeHandle(dashboardRef, () => ({
    getItemCount: item => counters[item as DoctorCountersSubRoutesCounters],
  }));

  const [countersLoading, setCountersLoading] = useState(true);

  const [getPortalUserStats] = useDashboardApiPortalUserStatsLazyQuery();

  const { currentFilters: oneToOneChatCurrentFilters } = useChatModelChatListingFilters({
    chatType: ChatType.ONE_TO_ONE,
  });
  const [oneToOneChatsSortPreference, setOneToOneChatsSortPreference] = useChatModelChatSortPreference({
    initialOrderBy: { field: ChatOrderByField.FEATURED_REQUEST_DUE_DATE, type: OrderByType.ASC },
    storageKey: PersistedDataKeys.OneToOneChatOrderBy,
  });
  const {
    pinnedOneToOneChats,
    pinnedOneToOneChatsLoading,
    totalCount: pinnedOneToOneChatsTotalCount,
    loadMore: loadMorePinnedOneToOneChats,
    sortBy: pinnedOneToOneChatsSortBy,
  } = chatModelBll.useOneToOneChatsPinned({
    skip: activeItem !== DashboardSubRoutes.CHATS_ONE_TO_ONE,
    numberOfRecords: NUMBER_OF_CHAT_LIST_ROWS,
    configuration: CHATS_LISTING_CONFIGURATION,
    initialFilters: oneToOneChatCurrentFilters,
    initialOrderBy: oneToOneChatsSortPreference,
    afterOrderByChange: setOneToOneChatsSortPreference,
  });
  const pinnedOneToOneChatsSortProps = {
    sortBy: pinnedOneToOneChatsSortBy,
    sortPreference: oneToOneChatsSortPreference,
  };

  const { currentFilters: clinicChatCurrentFilters } = useChatModelChatListingFilters({
    chatType: ChatType.ONE_TO_ONE,
  });
  const [clinicChatsSortPreference, setClinicChatsSortPreference] = useChatModelChatSortPreference({
    initialOrderBy: { field: ChatOrderByField.FEATURED_REQUEST_DUE_DATE, type: OrderByType.ASC },
    storageKey: PersistedDataKeys.ClinicChatOrderBy,
  });
  const {
    pinnedClinicChats,
    pinnedClinicChatsLoading,
    totalCount: pinnedClinicChatsTotalCount,
    loadMore: loadMorePinnedClinicChats,
    sortBy: pinnedClinicChatsSortBy,
  } = chatModelBll.useClinicChatsPinned({
    skip: activeItem !== DashboardSubRoutes.CHATS_CLINIC,
    numberOfRecords: NUMBER_OF_CHAT_LIST_ROWS,
    configuration: CHATS_LISTING_CONFIGURATION,
    initialFilters: clinicChatCurrentFilters,
    initialOrderBy: clinicChatsSortPreference,
    afterOrderByChange: setClinicChatsSortPreference,
  });
  const pinnedClinicChatsSortProps = {
    sortBy: pinnedClinicChatsSortBy,
    sortPreference: clinicChatsSortPreference,
  };

  const {
    infoItems: infos,
    totalCount: infoTotalCount,
    loading: infoLoading,
    paginationProps: infoPaginationProps,
    sortProps: infoSortProps,
  } = useInfoModelInfoList({
    isPinnedToHome: true,
    skip: activeItem !== DashboardSubRoutes.INFO,
  });

  const {
    surveyList: surveys,
    pagination: surveysPagination,
    loading: surveysLoading,
  } = useDoctorSurveyList({
    includeHiddenInDashboard: false,
    skip: activeItem !== DashboardSubRoutes.SURVEYS,
  });

  const {
    data: remindersData,
    loading: remindersLoading,
    refetch: remindersRefetch,
  } = useDashboardReminders({
    skip: activeItem !== DashboardSubRoutes.REMINDERS,
  });

  const formatDashboardActiveItemRoute = useFormatDashboardActiveItemRoute();

  const computeDefaultActiveItem = useComputeDefaultActiveItem({
    itemOrder: [
      DashboardSubRoutes.CHATS_ONE_TO_ONE,
      DashboardSubRoutes.CHATS_CLINIC,
      DashboardSubRoutes.INTRAMED,
      DashboardSubRoutes.INFO,
      DashboardSubRoutes.REMINDERS,
      DashboardSubRoutes.SURVEYS,
    ],
  });

  const formatBaseTile = (subRoute: DashboardDoctorSubRoutes): IDashboardTile => {
    if (subRoute === DashboardSubRoutes.CHATS) {
      const count = counters[DashboardSubRoutes.CHATS_ONE_TO_ONE] + counters[DashboardSubRoutes.CHATS_CLINIC];

      return {
        tile: subRoute,
        labelKey: DOCTOR_DASHBOARD_TILES_LABELS[subRoute],
        count,
        selected:
          activeItem === DashboardSubRoutes.CHATS_ONE_TO_ONE ||
          activeItem === DashboardSubRoutes.CHATS_CLINIC ||
          activeItem === subRoute,
        disabled: count === 0,
        routePath: formatDashboardActiveItemRoute(DashboardSubRoutes.CHATS_ONE_TO_ONE),
      };
    }

    if (subRoute === DashboardSubRoutes.CHATS_ONE_TO_ONE) {
      return {
        tile: subRoute,
        labelKey: DOCTOR_DASHBOARD_TILES_LABELS[!configuration?.isClinicsEnabled ? DashboardSubRoutes.CHATS : subRoute],
        count: counters[subRoute],
        selected: activeItem === subRoute || activeItem === DashboardSubRoutes.CHATS,
        disabled: counters[subRoute] === 0,
        routePath: formatDashboardActiveItemRoute(subRoute),
      };
    }

    if (subRoute === DashboardSubRoutes.CHATS_CLINIC) {
      return {
        tile: subRoute,
        labelKey: DOCTOR_DASHBOARD_TILES_LABELS[subRoute],
        count: counters[subRoute],
        selected: activeItem === subRoute,
        disabled: counters[subRoute] === 0,
        routePath: formatDashboardActiveItemRoute(subRoute),
      };
    }

    return {
      tile: subRoute,
      labelKey: DOCTOR_DASHBOARD_TILES_LABELS[subRoute],
      count: counters[subRoute],
      selected: activeItem === subRoute,
      disabled: counters[subRoute] === 0,
      routePath: formatDashboardActiveItemRoute(subRoute),
    };
  };

  const { showInfos } = useDoctorModelSecretaryAssociations();

  const tiles = [
    ...iif(
      !!configuration?.isPatientSelfRegistrationEnabled && !isNonDoctor,
      configuration?.isClinicsEnabled
        ? {
            ...formatBaseTile(DashboardSubRoutes.CHATS),
            icon: RequestsIcon,
            subHeaders: [
              { ...formatBaseTile(DashboardSubRoutes.CHATS_ONE_TO_ONE) },
              { ...formatBaseTile(DashboardSubRoutes.CHATS_CLINIC) },
            ],
          }
        : {
            ...formatBaseTile(DashboardSubRoutes.CHATS_ONE_TO_ONE),
            icon: RequestsIcon,
          }
    ),
    {
      ...formatBaseTile(DashboardSubRoutes.INTRAMED),
      icon: IntramedIcon,
    },
    ...iif(!!configuration?.isPatientSelfRegistrationEnabled && showInfos, {
      ...formatBaseTile(DashboardSubRoutes.INFO),
      icon: InfoRequestIcon,
    }),
    {
      ...formatBaseTile(DashboardSubRoutes.REMINDERS),
      icon: ReminderIcon,
    },
    ...iif(!!configuration?.hasAvailableSurveys, {
      ...formatBaseTile(DashboardSubRoutes.SURVEYS),
      icon: SurveysIcon,
    }),
  ];

  const compactTiles = !isMobileView && tiles.length <= 4 && (isNonDoctor || !configuration?.isClinicsEnabled);

  const totalCount = tiles.reduce((sum, tabData) => sum + tabData.count, 0);

  useEffect(() => {
    const handleInitialCounters = async (): Promise<void> => {
      if (!activeProfile || activeProfile.hasPostRegister) return;

      const { data: userStats } = await getPortalUserStats();

      const patientOrDoctorSurveysTotalCount = isPatient
        ? userStats?.patientSurveys?.totalCount
        : userStats?.doctorSurveys?.totalCount;

      const initialCounters = {
        [DashboardSubRoutes.CHATS_ONE_TO_ONE]: userStats?.portalUserStats?.chatStats.oneToOnePinnedToHomeCount ?? 0,
        [DashboardSubRoutes.CHATS_CLINIC]: userStats?.portalUserStats?.chatStats.clinicPinnedToHomeCount ?? 0,
        [DashboardSubRoutes.SURVEYS]: surveys?.totalCount ?? patientOrDoctorSurveysTotalCount ?? 0,
        [DashboardSubRoutes.REMINDERS]:
          remindersData?.dashboardReminders?.length ?? userStats?.dashboardReminders?.length ?? 0,
        [DashboardSubRoutes.INTRAMED]: userStats?.intramedThreadList.length ?? 0,
        [DashboardSubRoutes.INFO]: infoTotalCount ?? userStats?.infoThreads?.totalCount ?? 0,
      };

      setCounters(initialCounters);

      if (!activeItem && !isMobileView) {
        computeDefaultActiveItem(initialCounters);
      }

      setCountersLoading(false);
    };

    handleInitialCounters();
  }, []);

  useEffect(() => {
    if (!activeItem && !isMobileView) {
      computeDefaultActiveItem(counters);
    }
  }, [activeItem]);

  const isViewBoxPinnedClinicChatsVisible = pinnedClinicChats.length >= NUMBER_OF_CHAT_LIST_ROWS;
  const isViewBoxPinnedOneToOneChatsVisible = pinnedOneToOneChats.length >= NUMBER_OF_CHAT_LIST_ROWS;

  return {
    dashboardLoading: countersLoading,
    activeItem,
    activeCount:
      activeItem && activeItem !== DashboardSubRoutes.CHATS && activeItem !== DashboardSubRoutes.CARE_TEAM
        ? counters[activeItem]
        : null,
    tiles,
    totalCount,
    firstAccess,
    isPatientSelfRegistrationEnabled: !!configuration?.isPatientSelfRegistrationEnabled,
    pinnedOneToOneChats,
    pinnedOneToOneChatsTotalCount,
    isViewBoxPinnedOneToOneChatsVisible,
    loadMorePinnedOneToOneChats,
    pinnedOneToOneChatsLoading,
    pinnedOneToOneChatsSortProps,
    pinnedClinicChats,
    pinnedClinicChatsTotalCount,
    loadMorePinnedClinicChats,
    pinnedClinicChatsLoading,
    pinnedClinicChatsSortProps,
    isViewBoxPinnedClinicChatsVisible,
    infos,
    infoLoading,
    infoPaginationProps,
    infoSortProps,
    surveys,
    surveysLoading,
    surveysPagination,
    reminders: remindersData?.dashboardReminders ?? null,
    remindersLoading,
    remindersRefetch,
    compactTiles,
  };
};
