import { DropdownButton, Loader, useBreakpoints } from '@lib/react-components';
import { useContext } from 'react';

import { HeaderMobilePlusButton } from './components';
import { useDropdownButtonItems } from './hooks';
import { MobileHeaderContext } from './providers';

import { useUserModelStore } from 'features/Users/model';
import { i18n } from 'i18n';

export const HeaderButtons = (): JSX.Element => {
  const { isMobileView } = useBreakpoints();
  const { toggleStaffModalHandler } = useContext(MobileHeaderContext);
  const { activeProfile } = useUserModelStore();

  const dropdownButtonItems = useDropdownButtonItems();

  if (!activeProfile) {
    return <Loader />;
  }

  if (isMobileView) {
    return <HeaderMobilePlusButton onClick={toggleStaffModalHandler} />;
  }

  return (
    <DropdownButton
      menuItems={dropdownButtonItems}
      buttonText={i18n.t('components.accordionMenu.create')}
      disabled={activeProfile.hasPostRegister}
    />
  );
};
