import { Modal, ModalSize, StatusCard } from '@lib/react-components';
import { Button, Stack } from '@mui/material';

import { useStyles } from './styles';

import { i18n } from 'i18n';

interface IInfoModalProps {
  onCancel?: VoidFunction;
}

export const InfoModal = ({ onCancel }: IInfoModalProps): JSX.Element => {
  const { classes: localClasses } = useStyles();

  return (
    <Modal
      classes={{ contentBox: localClasses.contentBox }}
      size={ModalSize.MD}
      onCancel={onCancel}
      footer={
        <Stack>
          <Button variant="secondary" onClick={onCancel}>
            {i18n.t('general.buttons.close')}
          </Button>
        </Stack>
      }
    >
      <StatusCard text={i18n.t('features.patient.infoModal.info')} />
    </Modal>
  );
};
