import { isAfter } from 'date-fns';
import { useEffect, useMemo } from 'react';
import { generatePath, matchPath, Redirect, useLocation, useParams } from 'react-router-dom';

import { GlobalSearchDetailsTabType } from 'features/GlobalSearchDetails';
import { PORTAL_SLUG_ROUTE, ROUTE_PATHS } from 'routes/constants';

interface IDeprecatedRoute {
  removeDate: Date;
  from: string;
  to: string;
}

export const DEPRECATED_ROUTES: IDeprecatedRoute[] = [
  {
    from: `${PORTAL_SLUG_ROUTE}/request/free-clinics/:clinicId`,
    to: `${PORTAL_SLUG_ROUTE}${ROUTE_PATHS.freeClinic}`,
    removeDate: new Date('2023-10-14'),
  },
  {
    from: `${PORTAL_SLUG_ROUTE}/request/doctor/:doctorSlug/thread`,
    to: `${PORTAL_SLUG_ROUTE}${ROUTE_PATHS.doctor}`,
    removeDate: new Date('2023-10-14'),
  },
  {
    from: `${PORTAL_SLUG_ROUTE}/request/clinics/:clinicId`,
    to: `${PORTAL_SLUG_ROUTE}${ROUTE_PATHS.clinic}`,
    removeDate: new Date('2023-10-14'),
  },
  {
    from: `${PORTAL_SLUG_ROUTE}/request/doctor/:doctorSlug`,
    to: `${PORTAL_SLUG_ROUTE}${ROUTE_PATHS.doctor}`,
    removeDate: new Date('2023-10-14'),
  },
  {
    from: `${PORTAL_SLUG_ROUTE}/request/affiliated-doctor/:doctorSlug`,
    to: `${PORTAL_SLUG_ROUTE}${ROUTE_PATHS.affiliatedDoctor}`,
    removeDate: new Date('2023-10-14'),
  },
  {
    from: `${PORTAL_SLUG_ROUTE}/clinics/:clinicId`,
    to: `${PORTAL_SLUG_ROUTE}${ROUTE_PATHS.clinic}`,
    removeDate: new Date('2023-10-14'),
  },
  {
    from: `${PORTAL_SLUG_ROUTE}/clinics/:clinicId/reservation/:serviceId`,
    to: `${PORTAL_SLUG_ROUTE}${ROUTE_PATHS.clinicService}`,
    removeDate: new Date('2023-10-14'),
  },
  {
    from: `${PORTAL_SLUG_ROUTE}/request/doctor/:doctorSlug/consultation/:serviceId`,
    to: `${PORTAL_SLUG_ROUTE}${ROUTE_PATHS.doctorService}`,
    removeDate: new Date('2023-10-14'),
  },
  {
    from: `${PORTAL_SLUG_ROUTE}/request/doctor/:doctorSlug/videovisit/:serviceId`,
    to: `${PORTAL_SLUG_ROUTE}${ROUTE_PATHS.doctorService}`,
    removeDate: new Date('2023-10-14'),
  },
  {
    from: `${PORTAL_SLUG_ROUTE}/request/doctor/:doctorSlug/info`,
    to: `${PORTAL_SLUG_ROUTE}${ROUTE_PATHS.doctor}`,
    removeDate: new Date('2023-10-14'),
  },
  {
    from: `${PORTAL_SLUG_ROUTE}/request/affiliated-doctor/:doctorSlug/consultation/:serviceId`,
    to: `${PORTAL_SLUG_ROUTE}${ROUTE_PATHS.affiliatedDoctorService}`,
    removeDate: new Date('2023-10-14'),
  },
  {
    from: `${PORTAL_SLUG_ROUTE}/request/affiliated-doctor/:doctorSlug/videovisit/:serviceId`,
    to: `${PORTAL_SLUG_ROUTE}${ROUTE_PATHS.affiliatedDoctorService}`,
    removeDate: new Date('2023-10-14'),
  },
  {
    from: `${PORTAL_SLUG_ROUTE}/request/affiliated-doctor/:doctorSlug/info`,
    to: `${PORTAL_SLUG_ROUTE}${ROUTE_PATHS.affiliatedDoctor}`,
    removeDate: new Date('2023-10-14'),
  },
  {
    from: `${PORTAL_SLUG_ROUTE}/request/clinics/:clinicId/videovisit/:serviceId`,
    to: `${PORTAL_SLUG_ROUTE}${ROUTE_PATHS.clinicService}`,
    removeDate: new Date('2023-10-14'),
  },
  {
    from: `${PORTAL_SLUG_ROUTE}/request/clinics/:clinicId/consultation/:serviceId`,
    to: `${PORTAL_SLUG_ROUTE}${ROUTE_PATHS.clinicService}`,
    removeDate: new Date('2023-10-14'),
  },
  {
    from: `${PORTAL_SLUG_ROUTE}/request/free-clinics/:clinicId/videovisit/:serviceId`,
    to: `${PORTAL_SLUG_ROUTE}${ROUTE_PATHS.freeClinicService}`,
    removeDate: new Date('2023-10-14'),
  },
  {
    from: `${PORTAL_SLUG_ROUTE}/request/free-clinics/:clinicId/consultation/:serviceId`,
    to: `${PORTAL_SLUG_ROUTE}${ROUTE_PATHS.freeClinicService}`,
    removeDate: new Date('2023-10-14'),
  },
  {
    from: `${PORTAL_SLUG_ROUTE}/request/private`,
    to: `${PORTAL_SLUG_ROUTE}${ROUTE_PATHS.search}/${GlobalSearchDetailsTabType.PRIVATE_DOCTORS}`,
    removeDate: new Date('2023-11-01'),
  },
  {
    from: `${PORTAL_SLUG_ROUTE}/request/affiliated-doctor`,
    to: `${PORTAL_SLUG_ROUTE}${ROUTE_PATHS.search}/${GlobalSearchDetailsTabType.AFFILIATED_DOCTORS}`,
    removeDate: new Date('2023-11-01'),
  },
  {
    from: `${PORTAL_SLUG_ROUTE}/request/clinics`,
    to: `${PORTAL_SLUG_ROUTE}${ROUTE_PATHS.search}/${GlobalSearchDetailsTabType.CLINICS}`,
    removeDate: new Date('2023-11-01'),
  },
  {
    from: `${PORTAL_SLUG_ROUTE}/request/free-clinics`,
    to: `${PORTAL_SLUG_ROUTE}${ROUTE_PATHS.search}/${GlobalSearchDetailsTabType.FREE_CLINICS}`,
    removeDate: new Date('2023-11-01'),
  },
];

export const RootDeprecatedRouteRedirect = (): JSX.Element => {
  const { pathname, search } = useLocation();
  const params = useParams();
  const deprecatedRoute = useMemo(() => DEPRECATED_ROUTES.find(({ from }) => matchPath(pathname, from)), [pathname]);

  useEffect(() => {
    if (deprecatedRoute?.removeDate && isAfter(deprecatedRoute.removeDate, new Date())) {
      console.warn('This deprecated route should be removed', deprecatedRoute);
    }
  }, [deprecatedRoute]);

  return <Redirect to={`${generatePath(deprecatedRoute?.to || '', params)}${search}`} />;
};
