import { matchPath, useLocation } from 'react-router-dom';

import { usePortalSlug } from 'features/Portal';
import { usePortalSidebarItems } from 'features/Root/ui';
import { SidebarItemState } from 'lib/helpers/sidebarConfig';
import { ROUTE_PATHS } from 'routes/constants';

const REDIRECT_ROUTES = [ROUTE_PATHS.infoList, ROUTE_PATHS.infoById];

export const useRootModelInfoRedirectByState = (): boolean => {
  const portalSlug = usePortalSlug();
  const { pathname } = useLocation();
  const portalSidebarItems = usePortalSidebarItems();

  const isForbiddenPathName = REDIRECT_ROUTES.some(route =>
    matchPath(pathname, {
      path: `/${portalSlug}${route}`,
      exact: true,
    })
  );

  return (
    isForbiddenPathName &&
    !!portalSidebarItems?.flatMap(item => item).some(({ state }) => state === SidebarItemState.DISABLED)
  );
};
