import { Dispatch, SetStateAction, useMemo, useState } from 'react';

import { IPagination } from 'graphql/types/pagination';
import { DEFAULT_NUMBER_OF_RECORDS_PER_PAGE, PAGINATION_ROWS_PER_PAGE_OPTIONS } from 'lib/constants/pagination';

export type IUsePaginationReturnType = {
  onChangePage: Dispatch<SetStateAction<number>>;
  onChangeRowsPerPage: Dispatch<SetStateAction<number>>;
  page: number;
  rowsPerPage: number;
  rowsPerPageOptions: number[];
  paginationForRequest: IPagination;
};

export const usePagination = (): IUsePaginationReturnType => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_NUMBER_OF_RECORDS_PER_PAGE);
  const rowsPerPageOptions = PAGINATION_ROWS_PER_PAGE_OPTIONS;

  const pagination: IPagination = useMemo(
    () => ({
      numberOfRecords: rowsPerPage,
      page: page + 1,
    }),
    [rowsPerPage, page]
  );

  return useMemo(
    () => ({
      onChangePage: setPage,
      onChangeRowsPerPage: setRowsPerPage,
      page,
      rowsPerPage,
      rowsPerPageOptions,
      paginationForRequest: pagination,
    }),
    [setPage, setRowsPerPage, page, rowsPerPage, rowsPerPageOptions, pagination]
  );
};
