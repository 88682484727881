import { PersistedDataKeys } from '@lib/core';
import { InfoApiInfoThreads, useInfoApiInfoThreads } from '@lib/features-bll';
import { TablePaginationProps, usePaginationQueryParams, useSortPreference } from '@lib/react-components';
import { getUserFullName } from '@lib/utils';
import { max } from 'date-fns/esm';
import compact from 'lodash-es/compact';
import { useState } from 'react';

import { IInfoListingItem, IInfoListSortProps } from '../types';

import { Maybe, OrderByKeyword } from '__generated__/types';
import { useUserModelStore } from 'features/Users/model';
import { IRequestMessageFlag } from 'graphql/types/request';
import { i18n } from 'i18n';
import { isMessageRead } from 'lib/helpers';
import { persistedData } from 'lib/helpers/persistedData';

type InfoItems = InfoApiInfoThreads['infoThreads']['items'];
type InfoItem = InfoItems[number];

interface IUseInfoModelInfoListParams {
  isPinnedToHome?: Maybe<boolean>;
  skip?: boolean;
}

interface IUseInfoModelInfoListReturn {
  loading: boolean;
  infoItems: IInfoListingItem[];
  paginationProps: TablePaginationProps;
  totalCount?: number;
  sortProps: IInfoListSortProps;
  search: string;
  setSearch: (search: string) => void;
}

type UseInfoModelInfoList = (params?: IUseInfoModelInfoListParams) => IUseInfoModelInfoListReturn;

export const useInfoModelInfoList: UseInfoModelInfoList = ({ isPinnedToHome = null, skip = false } = {}) => {
  const { isPatient } = useUserModelStore();
  const [search, setSearch] = useState<string>('');
  const { setPage, setLimit, page, limit } = usePaginationQueryParams();
  const [sortPreference, setSortPreference] = useSortPreference(
    { field: 'updatedAt', type: OrderByKeyword.DESC },
    PersistedDataKeys.InfoOrderBy,
    persistedData
  );

  const { data, loading } = useInfoApiInfoThreads({
    variables: {
      filterBy: { isPinnedToHome },
      orderBy: sortPreference,
      pagination: {
        limit,
        offset: page * limit,
      },
    },
    skip,
  });

  const sortProps: IInfoListSortProps = {
    sortingValue: sortPreference.type,
    sortingItems: [
      {
        value: OrderByKeyword.ASC,
        label: i18n.t('features.info.infoList.sorting.updateAtAsc'),
      },
      {
        value: OrderByKeyword.DESC,
        label: i18n.t('features.info.infoList.sorting.updateAtDesc'),
      },
    ],
    sortChangeHandler: type => setSortPreference({ type }),
  };

  const filterByUserSearch = ({ recipients, patientInstitutionUser }: InfoItem): boolean =>
    getUserFullName(isPatient ? recipients[0].institutionUser?.user : patientInstitutionUser?.user)
      .toLocaleLowerCase()
      .includes(search.toLocaleLowerCase());

  const infoAdapter = ({
    id,
    createdAt,
    updatedAt,
    recipients,
    subject,
    patientInstitutionUser,
    metadata,
    isPending,
  }: InfoItem): IInfoListingItem => ({
    id,
    subject,
    createdAt,
    isPending,
    updatedAt: max(compact([metadata.lastMessage?.createdAt, updatedAt]).map(date => new Date(date))),
    firstThreadId: metadata.firstThreadId,
    isMessageRead: isMessageRead(metadata.lastMessage?.flags as IRequestMessageFlag[]),
    user: isPatient ? recipients[0].institutionUser?.user : patientInstitutionUser.user,
    avatarInstitutionUserId: isPatient ? recipients[0].institutionUser?.id : patientInstitutionUser.id,
  });

  return {
    loading,
    infoItems: (data?.infoThreads.items ?? []).filter(filterByUserSearch).map(infoAdapter),
    totalCount: data?.infoThreads.totalCount,
    paginationProps: {
      page,
      count: data?.infoThreads.totalCount || 0,
      rowsPerPage: limit,
      onPageChange: (_, newPage): void => setPage(newPage),
      onRowsPerPageChange: ({ target: { value } }): void => setLimit(parseInt(value, 10)),
    },
    sortProps,
    search,
    setSearch,
  };
};
