import { IPopoverItem } from '@lib/react-components';

import { ProfileType } from '__generated__/types';
import { useDoctorClinics, useDoctorClinicsFilters } from 'features/Clinics/components/DoctorClinicsList/hooks';
import { usePortal, usePortalConfiguration } from 'features/Portal';
import { rootConfig } from 'features/Root/config';
import { useRootModelPath } from 'features/Root/model';
import { useUserModelStore } from 'features/Users/model';
import { i18n } from 'i18n';
import { NEW_ENTITY_PARAM, ROUTE_PATHS } from 'routes/constants';

interface IPopoverItemWithFilters extends IPopoverItem {
  blockedPortal?: boolean;
  blockedRoles?: ProfileType[];
}

type UseDropdownButtonItems = () => IPopoverItemWithFilters[];

export const useDropdownButtonItems: UseDropdownButtonItems = () => {
  const configuration = usePortalConfiguration();
  const { getPath } = useRootModelPath();
  const { activeProfile } = useUserModelStore();
  const { portal } = usePortal();
  const { search, filters } = useDoctorClinicsFilters();
  const { data: clinics } = useDoctorClinics(search, filters);

  const links: IPopoverItemWithFilters[] = [
    {
      title: i18n.t('general.patient'),
      linkPath: ROUTE_PATHS.patientsCreate,
    },
    ...(configuration?.isPatientSelfRegistrationEnabled
      ? [
          {
            title: i18n.t('general.chat'),
            linkPath: ROUTE_PATHS.chatCreation,
            blockedRoles: [ProfileType.NON_DOCTOR],
          },
        ]
      : []),
    ...(clinics?.length
      ? [
          {
            title: i18n.t('general.reservation'),
            blockedPortal: !portal?.configuration?.isClinicsEnabled,
            linkPath: `${ROUTE_PATHS.clinics}${rootConfig.RESERVATION_QUERY_PARAMS}`,
          },
        ]
      : []),
    {
      title: i18n.t('general.intramed'),
      linkPath: ROUTE_PATHS.intramedCreate,
    },
    {
      title: i18n.t('general.equipe'),
      linkPath: ROUTE_PATHS.equipesCreate,
    },
    {
      title: i18n.t('general.reminder'),
      linkPath: `${ROUTE_PATHS.reminders}/${NEW_ENTITY_PARAM}`,
    },
    {
      title: i18n.t('general.invite'),
      linkPath: ROUTE_PATHS.inviteCreation,
    },
    ...(configuration?.hasAvailableSurveys
      ? [
          {
            title: i18n.t('general.survey'),
            linkPath: ROUTE_PATHS.surveyIssueCreation,
          },
        ]
      : []),
  ];

  const filteredLinks = links.filter(({ blockedPortal, blockedRoles }): boolean => {
    const blockedByRole = blockedRoles
      ? blockedRoles.some(blockedRole => blockedRole === activeProfile?.profileType)
      : false;

    return !blockedByRole && !blockedPortal;
  });

  return filteredLinks.map(({ linkPath, title }) => ({
    title,
    linkPath: linkPath ? getPath({ path: linkPath }) : undefined,
  }));
};
