import 'i18n';

import { ApolloProvider } from '@apollo/client';
import {
  ActiveProfileProvider,
  NotificationsProvider,
  PersistedDataKeys,
  PortalProvider,
  setupSentry,
} from '@lib/core';
import { CallProvider, Portals } from '@lib/features-bll';
import { ReactIconEnvironmentProvider, ScrollbarGlobalStyles } from '@lib/react-components';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { GlobalStyles } from 'tss-react';

import config from '../config.json';

import { rootStore } from './rootStore';

import { ActiveProfileProviderAdapter } from 'components/ActiveProfileProviderAdapter';
import { CallWebUi } from 'features/Calls/components';
import { useWebCall } from 'features/Calls/hooks';
import { PortalConfigProvider, PortalThemeProvider, PortalValidateProvider } from 'features/Portal';
import {
  DEPRECATED_ROUTES,
  RootAuthorizationProvider,
  RootDeprecatedRouteRedirect,
  RootEmptyPortalRouteRedirect,
  RootMain,
  RootWithoutPortalRouteRedirect,
} from 'features/Root/ui';
import { client } from 'graphql/apollo';
import { APP_WRAPPER_ID } from 'lib/constants';
import { NotificationsConsumer } from 'lib/consumers';
import { initGlobalSearchString } from 'lib/helpers/initGlobalSearchString';
import { initPersistedData } from 'lib/helpers/initPersistedData';
import { persistedData } from 'lib/helpers/persistedData';
import * as customValidation from 'lib/helpers/validation/customValidation';
import { LEGACY_UNAUTHORIZED_ROUTES, PORTAL_SLUG_ROUTE, UNAUTHORIZED_ROUTES_LIST } from 'routes/constants';

initPersistedData();

initGlobalSearchString();

setupSentry({
  application: 'web',
  dsn: config.SentryDsn,
  environment: config.SentryEnvironment,
  release: config.SentryRelease,
});

customValidation.init();

const defaultPortal = persistedData.get(PersistedDataKeys.WelmedSlug) || Portals.WELCOMEDICINE;

const rootDomNode = document.getElementById(APP_WRAPPER_ID);
if (!rootDomNode) throw new Error('Failed to find the root element');
const root = createRoot(rootDomNode);

root.render(
  <ApolloProvider client={client}>
    <Provider store={rootStore}>
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <ReactIconEnvironmentProvider>
            <PortalProvider>
              <PortalThemeProvider>
                <CssBaseline />
                <GlobalStyles
                  styles={{
                    '.draggable-modal-wrapper': {
                      width: 0,
                    },
                  }}
                />
                <ScrollbarGlobalStyles />
                <Switch>
                  <Redirect exact from="/" to={`/${defaultPortal}`} />
                  <Route path={[...LEGACY_UNAUTHORIZED_ROUTES, ...UNAUTHORIZED_ROUTES_LIST]} exact>
                    <RootWithoutPortalRouteRedirect defaultPortal={defaultPortal} />
                  </Route>
                  <Route path={[...DEPRECATED_ROUTES.map(({ from }) => from)]}>
                    <RootDeprecatedRouteRedirect />
                  </Route>
                  <Route path={PORTAL_SLUG_ROUTE}>
                    <Route path={PORTAL_SLUG_ROUTE} exact>
                      <RootEmptyPortalRouteRedirect />
                    </Route>
                    <PortalConfigProvider>
                      <RootAuthorizationProvider>
                        <ActiveProfileProvider>
                          <ActiveProfileProviderAdapter />
                          <CallProvider callCreator={useWebCall}>
                            <CallWebUi />
                            <NotificationsProvider>
                              <NotificationsConsumer />
                              <PortalValidateProvider>
                                <RootMain />
                              </PortalValidateProvider>
                            </NotificationsProvider>
                          </CallProvider>
                        </ActiveProfileProvider>
                      </RootAuthorizationProvider>
                    </PortalConfigProvider>
                  </Route>
                </Switch>
              </PortalThemeProvider>
            </PortalProvider>
          </ReactIconEnvironmentProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    </Provider>
  </ApolloProvider>
);
