export enum DashboardSubRoutes {
  CHATS = 'chats',
  CHATS_ONE_TO_ONE = 'chatsOneToOne',
  CHATS_CLINIC = 'chatsClinic',
  INFO = 'info',
  INTRAMED = 'intramed',
  SURVEYS = 'surveys',
  CARE_TEAM = 'careTeam',
  REMINDERS = 'reminders',
}
