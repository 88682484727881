import {
  BREAK_POINTS,
  defaultTransition,
  fontWeight,
  getDefaultBoxShadow,
  leftNavigationCollapsedWidth,
  leftNavigationWidth,
  zIndexValues,
} from '@lib/react-components';
import { makeStyles } from 'tss-react/mui';

import { VERIFY_USER_NOTIFICATION_HEIGHT_MOBILE } from './constants';

export const useStyles = makeStyles()(theme => ({
  verifyUserNotificationWrapper: {
    width: '100%',
    color: theme.palette.warning.dark,
    transition: defaultTransition,
    cursor: 'pointer',
    backgroundColor: theme.palette.yellow.main,
    fontWeight: fontWeight.bold,
    padding: 5,
    zIndex: zIndexValues.header,
    boxShadow: getDefaultBoxShadow(theme.palette.shadow.main),
    position: 'fixed',
    top: 0,
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      padding: '5px 16px',
      height: VERIFY_USER_NOTIFICATION_HEIGHT_MOBILE,
    },
    '&:hover': {
      color: theme.palette.black.main,
    },
  },

  fullScreen: {
    marginTop: -34,
  },

  sidebarExpanded: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${leftNavigationWidth}px)`,
      left: leftNavigationWidth,
    },
  },

  sidebarCollapsed: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${leftNavigationCollapsedWidth}px)`,
      left: leftNavigationCollapsedWidth,
    },
  },

  resendCode: {
    fontSize: 14,
    lineHeight: '17px',
    textAlign: 'center',
    cursor: 'pointer',
    marginTop: 20,
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      textAlign: 'left',
    },
  },

  resendCodeButton: {
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },

  codeField: {
    width: '100%',
  },

  formTitle: {
    fontSize: 26,
    lineHeight: '28px',
    textAlign: 'center',
    marginBottom: 20,
  },

  formText: {
    fontSize: 14,
    lineHeight: '140%',
    marginBottom: 54,
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      marginBottom: 30,
    },
  },

  submitButton: {
    maxWidth: 200,
    fontSize: 18,
    lineHeight: '22px',
    '&.confirmButton': {
      fontWeight: fontWeight.bold,
    },
  },

  submitModalButton: {
    maxWidth: 200,
  },

  responseError: {
    color: theme.palette.error.main,
  },

  form: {
    marginBottom: 'auto',
  },

  content: {
    height: '100%',
    '& > .MuiBox-root:last-child': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
}));
