import { Portals } from '@lib/features-bll';
import { useModalState } from '@lib/react-components';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { usePortal } from 'features/Portal';
import { useRootModelPath } from 'features/Root/model';
import { LoginOrRegisterBlockingModalsControl } from 'features/Users/components';
import { DEFAULT_PUBLIC_ROUTE, ROUTE_PATHS } from 'routes/constants';
import { IRoute, RouteGuardReturnType } from 'routes/types';

type PublicRouteProps = RouteProps & IRoute;

export const RootPublicRoute = (routeProps: PublicRouteProps): JSX.Element => {
  const [isBlockingModalOpened, , closeBlockingModal] = useModalState(true);
  const { getPath } = useRootModelPath();
  const { portal } = usePortal();
  const isBravomed = portal?.slug === Portals.BRAVOMED;

  if (
    routeProps?.portalSettingsGuards?.some(
      portalSettingsGuard => portalSettingsGuard(portal) === RouteGuardReturnType.Denied
    )
  ) {
    return <Redirect to={getPath({ path: isBravomed ? ROUTE_PATHS.assistedSearch : DEFAULT_PUBLIC_ROUTE })} />;
  }

  return (
    <>
      <Route {...routeProps} />
      {routeProps?.forbiddenAction && isBlockingModalOpened && (
        <LoginOrRegisterBlockingModalsControl handleCloseModalClick={closeBlockingModal} isForbiddenAction />
      )}
    </>
  );
};
