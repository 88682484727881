import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { GlobalSearchDetails, globalSearchDetailsModel } from 'features/GlobalSearchDetails';
import { useRootModelPath } from 'features/Root/model';
import { ROUTE_PATHS } from 'routes/constants';

export const GlobalSearchDetailsDoctorTab = (): JSX.Element => {
  const { goToPath } = useRootModelPath();
  const { globalSearchDetails, fetchMore, loading } = globalSearchDetailsModel.useGlobalSearchDetailsDoctors();
  const doctors = globalSearchDetailsModel.getDoctorTabData(globalSearchDetails?.doctors.items);
  const { search } = useLocation();

  const onDoctorCardClickHandler = (doctorSlug?: string): void => {
    if (doctorSlug) {
      goToPath({ path: ROUTE_PATHS.doctor, pathParams: { doctorSlug }, search });
    }
  };

  return (
    <GlobalSearchDetails.TabWrapper
      listLoading={loading}
      fetchMore={fetchMore}
      data={doctors}
      itemContent={(_, doctor): ReactNode => (
        <GlobalSearchDetails.CardDoctor
          {...doctor}
          key={doctor.portalUserId}
          onDoctorCardClickHandler={onDoctorCardClickHandler}
        />
      )}
    />
  );
};
