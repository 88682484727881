import { gt, isNil } from 'lodash-es';
import { useHistory } from 'react-router-dom';

import { DashboardSubRoutes } from '../config';
import { DoctorCounters, PatientCounters } from '../types';

import { useFormatDashboardActiveItemRoute } from './useFormatDashboardActiveItemRoute';

type CountersUnion = DoctorCounters | PatientCounters;

type UseComputeDefaultActiveItemProps<CountersKeys> = {
  itemOrder: DashboardSubRoutes[];
  fallback?: CountersKeys;
};

type UseComputeDefaultActiveItemReturn<Counters> = (counters: Counters) => void;

type UseComputeDefaultActiveItem = <Counters extends CountersUnion>(
  props: UseComputeDefaultActiveItemProps<keyof Counters>
) => UseComputeDefaultActiveItemReturn<Counters>;

export const useComputeDefaultActiveItem: UseComputeDefaultActiveItem = ({ itemOrder, fallback }) => {
  const history = useHistory();

  const formatDashboardActiveItemRoute = useFormatDashboardActiveItemRoute();

  return countersObj => {
    const initialActiveItem = itemOrder.find(tile => {
      const tileCounter = countersObj[tile as keyof typeof countersObj];

      return !isNil(tileCounter) && gt(tileCounter, 0);
    });

    if (initialActiveItem || fallback) {
      history.replace(formatDashboardActiveItemRoute((initialActiveItem ?? fallback) as DashboardSubRoutes));
    }
  };
};
