import { DashboardSubRoutes } from '../config';

import { useRootModelPath } from 'features/Root/model';
import { ROUTE_PATHS } from 'routes/constants';

type useFormatDashboardActiveItemRouteReturn = (subRoute: DashboardSubRoutes) => string;

export const useFormatDashboardActiveItemRoute = (): useFormatDashboardActiveItemRouteReturn => {
  const { getPath } = useRootModelPath();

  return subRoute => getPath({ path: ROUTE_PATHS.dashboardItem, pathParams: { activeItem: subRoute } });
};
