import {
  OneToOneChatPatientWritePolicy,
  useChatApiClinicChatPatientWritePolicyLazyQuery,
  useChatApiOneToOneChatPatientWritePolicyLazyQuery,
} from '../api';
import { ActiveChatOrRequestType } from '../config';

import { AllowDenyPolicyType } from '__generated__/types';

interface IOneToOneChatParams {
  portalUserId: string;
}

interface IClinicChatParams {
  clinicId: string;
  patientPortalUserId: string;
}

type UseChatPatientWritePolicyReturnParams = IOneToOneChatParams | IClinicChatParams;

interface ICommonChat {
  featuredRequest?: OneToOneChatPatientWritePolicy['oneToOneChat']['featuredRequest'];
  numberOfChatItems?: OneToOneChatPatientWritePolicy['oneToOneChat']['numberOfChatItems'];
}

const isOneToOne = (params: UseChatPatientWritePolicyReturnParams): params is IOneToOneChatParams =>
  (params as IOneToOneChatParams).portalUserId !== undefined;

const isClinic = (params: UseChatPatientWritePolicyReturnParams): params is IClinicChatParams =>
  (params as IClinicChatParams).clinicId !== undefined;

type UseChatModelChatPatientWritePolicyReturn = (
  params: UseChatPatientWritePolicyReturnParams
) => Promise<{ isPatientBlocked: boolean; activeChatOrRequest?: ActiveChatOrRequestType }>;

const getActiveChatOrRequest = (
  chat: ICommonChat | undefined,
  isPatientBlocked: boolean
): ActiveChatOrRequestType | undefined => {
  if (chat?.featuredRequest?.isStatusFinal === false) {
    return ActiveChatOrRequestType.REQUEST;
  }

  if (chat?.numberOfChatItems && chat.numberOfChatItems > 0 && !isPatientBlocked) {
    return ActiveChatOrRequestType.CHAT;
  }

  return undefined;
};

export const useChatModelChatPatientWritePolicy = (): UseChatModelChatPatientWritePolicyReturn => {
  const [getOneToOneChatPolicy] = useChatApiOneToOneChatPatientWritePolicyLazyQuery();
  const [geClinicChatPolicy] = useChatApiClinicChatPatientWritePolicyLazyQuery();

  return async params => {
    let isPatientBlocked = false;
    let chat;

    if (isOneToOne(params)) {
      const { data } = await getOneToOneChatPolicy({ variables: params });
      isPatientBlocked = data?.oneToOneChat?.patientWritePolicy === AllowDenyPolicyType.DENY;
      chat = data?.oneToOneChat;
    }

    if (isClinic(params)) {
      const { data } = await geClinicChatPolicy({ variables: params });
      isPatientBlocked = data?.clinicChat?.patientWritePolicy === AllowDenyPolicyType.DENY;
      chat = data?.clinicChat;
    }

    return {
      isPatientBlocked,
      activeChatOrRequest: getActiveChatOrRequest(chat, isPatientBlocked),
    };
  };
};
