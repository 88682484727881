import { ROUTE_PATHS } from './routePaths';

export const ALLOWED_LOGIN_OBJECTS: string[] = [
  ROUTE_PATHS.loginByPortal,
  ROUTE_PATHS.gsdSsoSignIn,
  ROUTE_PATHS.gsdSsoRedirect,
  ROUTE_PATHS.ssoLogin,
  ROUTE_PATHS.registrationByTypeAndPortal,
  ROUTE_PATHS.forgotPasswordByPortal,
  ROUTE_PATHS.forgotPasswordConfirmByPortal,
  ROUTE_PATHS.multipleRoleRegister,
  ROUTE_PATHS.userClaimAcceptedPatientRegistration,
  ROUTE_PATHS.userClaimSucceedPatientRegistration,
  ROUTE_PATHS.draftDirectMessageConfirmation,
];
