import { useHistory, useLocation } from 'react-router-dom';

import { useRootModelPath } from 'features/Root/model';
import { ROUTE_PATHS } from 'routes/constants';

type UseGlobalSearchDetailsTabChangeHandler = () => (value?: string, query?: string) => void;

export const useGlobalSearchDetailsTabChangeHandler: UseGlobalSearchDetailsTabChangeHandler = () => {
  const { getPath, goToPath } = useRootModelPath();
  const { replace } = useHistory();
  const { search } = useLocation();

  return (value, query) => {
    if (value) {
      replace(
        getPath({ path: ROUTE_PATHS.searchByTabType, pathParams: { activeTab: value }, search: query ?? search })
      );
    } else {
      goToPath({ path: ROUTE_PATHS.search, search: query ?? search });
    }
  };
};
