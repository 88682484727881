import { PersistedDataKeys } from '@lib/core';
import { DEFAULT_SORTING, IChatSorting } from '@lib/features-bll';
import { useSortPreference } from '@lib/react-components';

import { ChatOrderByField, OrderByType } from '__generated__/types';
import { persistedData } from 'lib/helpers/persistedData';

interface IUseChatModelChatSortPreferenceParams {
  initialOrderBy?: IChatSorting;
  storageKey:
    | PersistedDataKeys.OneToOneChatOrderBy
    | PersistedDataKeys.ClinicChatOrderBy
    | PersistedDataKeys.RequestChatOrderBy;
}
type UseChatModelChatSortPreference = (
  params: IUseChatModelChatSortPreferenceParams
) => ReturnType<typeof useSortPreference<ChatOrderByField, OrderByType>>;

export const useChatModelChatSortPreference: UseChatModelChatSortPreference = ({
  initialOrderBy = DEFAULT_SORTING,
  storageKey,
}) => useSortPreference(initialOrderBy, storageKey, persistedData);
