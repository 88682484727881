import { AddIcon, ArrowForwardIcon, ExpandLessIcon, MaterialSymbolsIcon } from '@lib/icons';
import { getDefaultBoxShadow } from '@lib/react-components';
import { Accordion, AccordionSummary, Box, Stack, Typography, useTheme } from '@mui/material';
import { useState } from 'react';

import { IMobileSideNavigationAccordionMenuProps } from './types';

import { useDropdownButtonItems } from 'features/Root/ui';
import { i18n } from 'i18n';
import { LEFT_NAVIGATION_MOBILE_WIDTH } from 'lib/constants/scroll';

export const MobileSideNavigationAccordionMenu = ({
  goToLink,
}: IMobileSideNavigationAccordionMenuProps): JSX.Element => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const accordionItems = useDropdownButtonItems();
  const { palette } = useTheme();

  const accordionToggleHandler: VoidFunction = () => setOpen(!isOpen);

  return (
    <Accordion
      square
      expanded={isOpen}
      onChange={accordionToggleHandler}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        left: 0,
        bottom: 0,
        width: `${LEFT_NAVIGATION_MOBILE_WIDTH}px`,
        cursor: 'pointer',
        boxShadow: getDefaultBoxShadow(palette.shadow.main),
        backgroundColor: palette.surface.default,
      }}
    >
      <AccordionSummary sx={{ padding: `30px 10px ${isOpen ? 18 : 30}px 25px`, color: palette.generic.dark }}>
        <MaterialSymbolsIcon icon={<AddIcon />} sx={{ marginRight: '15px' }} />
        <Typography variant="h7" marginRight="30px">
          {i18n.t('components.accordionMenu.create')}
        </Typography>
        <MaterialSymbolsIcon
          icon={isOpen ? <ExpandLessIcon fontWeight="300" /> : <ArrowForwardIcon fontWeight="300" />}
          fontSize={isOpen ? 26 : 16}
          sx={{ marginLeft: '75px' }}
        />
      </AccordionSummary>
      {isOpen && (
        <Stack>
          {accordionItems.map(({ title, linkPath }) => (
            <Box
              sx={{
                padding: '12px 0 12px 55px',
                color: palette.black.main,
                '&:last-child': {
                  paddingBottom: '24px',
                },
              }}
              onClick={goToLink(linkPath)}
            >
              <Typography variant="body1">{title}</Typography>
            </Box>
          ))}
        </Stack>
      )}
    </Accordion>
  );
};
