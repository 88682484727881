import { DashboardSubRoutes } from './dashboardSubRoutes';

export const PATIENT_DASHBOARD_TILES_LABELS = {
  [DashboardSubRoutes.CHATS]: 'features.dashboard.tiles.patient.chats',
  [DashboardSubRoutes.CHATS_ONE_TO_ONE]: 'features.dashboard.tiles.patient.oneToOneChats',
  [DashboardSubRoutes.CHATS_CLINIC]: 'features.dashboard.tiles.patient.clinicChats',
  [DashboardSubRoutes.INFO]: 'features.dashboard.tiles.patient.info',
  [DashboardSubRoutes.REMINDERS]: 'features.dashboard.tiles.patient.reminders',
  [DashboardSubRoutes.SURVEYS]: 'features.dashboard.tiles.patient.surveys',
  [DashboardSubRoutes.CARE_TEAM]: 'features.dashboard.tiles.patient.careTeam',
};

export const DOCTOR_DASHBOARD_TILES_LABELS = {
  [DashboardSubRoutes.CHATS]: 'features.dashboard.tiles.doctor.chats',
  [DashboardSubRoutes.CHATS_ONE_TO_ONE]: 'features.dashboard.tiles.doctor.oneToOneChats',
  [DashboardSubRoutes.CHATS_CLINIC]: 'features.dashboard.tiles.doctor.clinicChats',
  [DashboardSubRoutes.INFO]: 'features.dashboard.tiles.doctor.info',
  [DashboardSubRoutes.REMINDERS]: 'features.dashboard.tiles.doctor.reminders',
  [DashboardSubRoutes.SURVEYS]: 'features.dashboard.tiles.doctor.surveys',
  [DashboardSubRoutes.INTRAMED]: 'features.dashboard.tiles.doctor.intramed',
};
