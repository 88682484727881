import { IChatsListingConfiguration } from '@lib/features-bll';
import { DASH_EMPTY_VALUE } from '@lib/utils';

import {
  GsdHsrClinicSubType,
  RequestAssignmentStatus,
  RequestDueStatus,
  RequestStatus,
  ServiceBookingType,
} from '__generated__/types';
import { i18n } from 'i18n';

export const NUMBER_OF_CHAT_LIST_ROWS = 10;

export const CHATS_LISTING_CONFIGURATION: IChatsListingConfiguration = {
  status: {
    [RequestDueStatus.CLOSE_DUE]: {
      label: i18n.t('components.dashboardWarning.requests.statuses.activeDeadLine'),
      bgColor: 'pastelYellow.dark',
      icon: <div>Icona</div>,
    },
    [RequestDueStatus.PAST_DUE]: {
      label: i18n.t('components.dashboardWarning.requests.statuses.activeDelay'),
      bgColor: 'pastelRed.main',
      icon: <div>Icona</div>,
    },
    [RequestStatus.ACTIVE]: {
      label: i18n.t('components.dashboardWarning.requests.statuses.active'),
      bgColor: 'pastelGreen.main',
      icon: <div>Icona</div>,
    },
    [RequestStatus.CANCELLED]: {
      label: i18n.t('components.dashboardWarning.requests.statuses.cancelled'),
      bgColor: 'generic.main',
      textColor: 'white.main',
      icon: <div>Icona</div>,
    },
    [RequestStatus.CLOSED]: {
      label: i18n.t('components.dashboardWarning.requests.statuses.closed'),
      bgColor: 'generic.dark',
      textColor: 'white.main',
      icon: <div>Icona</div>,
    },
    [RequestStatus.DRAFT]: {
      label: i18n.t('components.dashboardWarning.requests.statuses.draft'),
      bgColor: 'pastelYellow.dark',
      icon: <div>Icona</div>,
    },
    [RequestStatus.PAYMENT_CANCELLED]: {
      label: i18n.t('components.dashboardWarning.requests.statuses.cancelled'),
      bgColor: 'pastelRed.light',
      icon: <div>Icona</div>,
    },
    [RequestStatus.PAYMENT_FAILED]: {
      label: i18n.t('components.dashboardWarning.requests.statuses.paymentFailed'),
      bgColor: 'pastelYellow.dark',
      icon: <div>Icona</div>,
    },
    [RequestStatus.PENDING_DOCTOR_APPROVAL]: {
      label: i18n.t('components.dashboardWarning.requests.statuses.pendingDoctorApproval'),
      bgColor: 'pastelYellow.dark',
      icon: <div>Icona</div>,
    },
    [RequestStatus.PENDING_PATIENT_APPROVAL]: {
      label: i18n.t('components.dashboardWarning.requests.statuses.pendingPatientApproval'),
      bgColor: 'pastelYellow.dark',
      icon: <div>Icona</div>,
    },
    [RequestStatus.PENDING_PAYMENT]: {
      label: i18n.t('components.dashboardWarning.requests.statuses.pendingPayment'),
      bgColor: 'pastelYellow.dark',
      icon: <div>Icona</div>,
    },
    [RequestAssignmentStatus.UNASSIGNED]: {
      label: i18n.t('components.dashboardWarning.requests.statuses.unassigned'),
      bgColor: 'pastelYellow.main',
      icon: <div>Icona</div>,
    },
    [RequestAssignmentStatus.ASSIGNED]: {
      label: i18n.t('components.dashboardWarning.requests.statuses.assigned'),
      bgColor: 'pastelYellow.dark',
      icon: <div>Icona</div>,
    },
  },
  bookingType: {
    [ServiceBookingType.DOCTOR]: i18n.t('request.bookingType.doctor'),
    [ServiceBookingType.CLINIC]: i18n.t('request.bookingType.clinic'),
    [ServiceBookingType.AFFILIATION]: i18n.t('request.bookingType.affiliation'),
    [GsdHsrClinicSubType.SSN]: i18n.t('request.clinicSubType.ssn'),
    [GsdHsrClinicSubType.GENERIC]: i18n.t('request.clinicSubType.generic'),
    [GsdHsrClinicSubType.NON_INTEGRATED]: i18n.t('request.clinicSubType.nonIntegrated'),
  },
  emptyValue: DASH_EMPTY_VALUE,
};
