import { BREAK_POINTS, fontWeight, leftNavigationCollapsedWidth, leftNavigationWidth } from '@lib/react-components';
import { makeStyles } from 'tss-react/mui';

const toggleUserMenuButtonSize = 45;

export const useStyles = makeStyles()(theme => ({
  sidebarExpanded: {
    [theme.breakpoints.up(BREAK_POINTS.MOBILE)]: {
      width: `calc(100% - ${leftNavigationWidth}px)`,
    },
  },

  sidebarCollapsed: {
    [theme.breakpoints.up(BREAK_POINTS.MOBILE)]: {
      width: `calc(100% - ${leftNavigationCollapsedWidth}px)`,
    },
  },

  toolBar: {
    padding: 0,
    minHeight: 70,
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      minHeight: 90,
      display: 'flex',
      justifyContent: 'space-between',
    },
  },

  menuButton: {
    marginRight: 16,
    [theme.breakpoints.up(BREAK_POINTS.MOBILE)]: {
      display: 'none',
    },
    [theme.breakpoints.down('lg')]: {
      marginRight: 0,
    },
  },

  menuRoot: {
    position: 'relative',
  },

  menuItem: {
    width: 260,
    '&.MuiListItem-button': {
      paddingLeft: 40,
      color: theme.palette.generic.dark,
      '&:hover': {
        color: theme.palette.black.main,
      },
    },
    '&.MuiMenuItem-root': {
      paddingLeft: 40,
      color: theme.palette.generic.dark,
    },
    '& .MuiTypography-root': {
      paddingLeft: 0,
      fontWeight: fontWeight.regular,
    },
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: theme.palette.background.default,
    },
    '&.Mui-selected .MuiTypography-body1': {
      fontWeight: fontWeight.bold,
    },
  },

  divider: {
    backgroundColor: theme.palette.lines.table,
    margin: '8px 0',
  },

  toggleButton: {
    marginLeft: 25,
    padding: 0,
    borderRadius: '50%',
  },

  toggleUserMenuItemLink: {
    '&.rightMenuLink': {
      padding: '10px 0',
    },
  },

  toggleUserMenuButton: {
    minHeight: toggleUserMenuButtonSize,
    marginLeft: 10,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.MuiButton-root': {
      width: toggleUserMenuButtonSize,
      height: toggleUserMenuButtonSize,
      borderRadius: '50%',
      padding: 0,
    },
  },

  avatarMobile: {
    height: 56,
    width: 56,
  },

  portalIcon: {
    marginRight: 25,
  },
}));
