import { ROUTE_PATHS, UNAUTHORIZED_ROUTES_LIST } from 'routes/constants';

interface IUseHiddenSidebarRoutesReturn {
  hiddenSidebarRoutes: string[];
  mobileHiddenSidebarRoutes: string[];
}

export const useRootModelHiddenSidebarRoutes = (): IUseHiddenSidebarRoutesReturn => ({
  hiddenSidebarRoutes: [
    ...UNAUTHORIZED_ROUTES_LIST,
    ROUTE_PATHS.payment,
    ROUTE_PATHS.fileRedirect,
    ROUTE_PATHS.videoVisitRedirectTo,
    ROUTE_PATHS.videoMeetingRedirectTo,
    ROUTE_PATHS.assistedSearch,
  ],
  mobileHiddenSidebarRoutes: [
    ROUTE_PATHS.consultationById,
    ROUTE_PATHS.infoById,
    ROUTE_PATHS.intramedById,
    ROUTE_PATHS.surveyDetails,
    ROUTE_PATHS.chatOneToOne,
    ROUTE_PATHS.chatClinic,
    ROUTE_PATHS.dashboardItem,
    ROUTE_PATHS.search,
    ROUTE_PATHS.searchByTabType,
    ROUTE_PATHS.assistedSearch,
    ROUTE_PATHS.payment,
    ROUTE_PATHS.inAppBrowser,
  ],
});
