import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import {
  GlobalSearchDetails,
  GlobalSearchDetailsCardClinic,
  globalSearchDetailsModel,
} from 'features/GlobalSearchDetails';
import { useRootModelPath } from 'features/Root/model';
import { ROUTE_PATHS } from 'routes/constants';

export const GlobalSearchDetailsFreeClinicsTab = (): JSX.Element => {
  const { goToPath } = useRootModelPath();
  const { globalSearchDetails, loading, fetchMore } = globalSearchDetailsModel.useGlobalSearchDetailsFreeClinics();
  const freeClinics = globalSearchDetailsModel.getClinicTabData(globalSearchDetails?.freeClinics?.items);
  const { search } = useLocation();

  const onFreeClinicCardClickHandler = (clinicId: string): void =>
    goToPath({ path: ROUTE_PATHS.freeClinic, pathParams: { clinicId }, search });

  return (
    <GlobalSearchDetails.TabWrapper
      listLoading={loading}
      fetchMore={fetchMore}
      data={freeClinics}
      itemContent={(_, freeClinic): ReactNode => (
        <GlobalSearchDetailsCardClinic
          {...freeClinic}
          key={freeClinic.id}
          onClinicCardClickHandler={onFreeClinicCardClickHandler}
        />
      )}
    />
  );
};
