import { InvitesConfigInviteUrlKey } from '@lib/features-bll';
import { MaterialSymbolsIcon, RunningWithErrorsIcon } from '@lib/icons';
import { Modal, MODAL_CLASSES, ModalSize, StatusCard, useBreakpoints } from '@lib/react-components';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import { Trans } from 'react-i18next';

import { Maybe } from '__generated__/types';
import { useRegistrationModelRegistrationLinks } from 'features/Registration/model';
import { UnauthorizedUserChatMessageUrlKeys } from 'features/UnauthorizedUserChatMessage/config';
import { i18n } from 'i18n';
import { REGISTRATION_ROUTES_TYPES } from 'lib/constants/registration';
import { ROUTE_PATHS } from 'routes/constants';

interface IUnauthorizedUserChatMessageProceedToRegistrationModalProps {
  draftDirectMessageId?: string;
  isClinic?: boolean;
  patientInviteId?: Maybe<string>;
  onCancel: VoidFunction;
}

export const UnauthorizedUserChatMessageProceedToRegistrationModal = ({
  patientInviteId,
  draftDirectMessageId,
  onCancel,
  isClinic,
}: IUnauthorizedUserChatMessageProceedToRegistrationModalProps): JSX.Element => {
  const { palette } = useTheme();
  const { isMobileView } = useBreakpoints();
  const registrationHandler = useRegistrationModelRegistrationLinks();

  const handleRegisterClick = registrationHandler({
    type: REGISTRATION_ROUTES_TYPES.PATIENT,
    searchObj: {
      [InvitesConfigInviteUrlKey.patientInviteId]: patientInviteId,
      [UnauthorizedUserChatMessageUrlKeys.draftDirectMessageId]: draftDirectMessageId,
    },
    skipNulls: true,
    redirectForApp: ROUTE_PATHS.chatsOneToOneList,
  });

  return (
    <Modal
      sx={{
        [`& .${MODAL_CLASSES.modalFooter}`]: {
          display: 'flex',
          justifyContent: 'center',
        },
      }}
      disableClickEvent
      bluredBackDrop
      fullscreenOnMobile={false}
      onCancel={onCancel}
      cancelConfirmation
      cancelConfirmationModalSize={ModalSize.SM}
      cancelConfirmationTitle={
        isMobileView ? (
          i18n.t('features.unauthorizedUserChatMessage.proceedToRegistrationCancelModal.title')
        ) : (
          <Typography variant="h6" textAlign="center">
            {i18n.t('features.unauthorizedUserChatMessage.proceedToRegistrationCancelModal.title')}
          </Typography>
        )
      }
      cancelConfirmationBody={
        <StatusCard
          status="info"
          text={i18n.t('features.unauthorizedUserChatMessage.proceedToRegistrationCancelModal.info', {
            context: isClinic ? 'clinic' : '',
          })}
        />
      }
      footer={
        <Button onClick={handleRegisterClick} sx={{ minWidth: '180px' }}>
          {i18n.t('general.buttons.register')}
        </Button>
      }
    >
      <Stack gap="8px" alignItems="center">
        <MaterialSymbolsIcon fontSize={96} icon={<RunningWithErrorsIcon fill={palette.green.main} />} />
        <Typography textAlign="center" variant="h6">
          <Trans i18n={i18n} i18nKey="features.unauthorizedUserChatMessage.proceedToRegistrationModal.info" />
        </Typography>
      </Stack>
    </Modal>
  );
};
