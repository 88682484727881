import { useDashboardItemTitle } from './useDashboardItemTitle';
import { useDashboardPatientViewEmptyState } from './useDashboardPatientViewEmptyState';
import { useDashboardWelcomeTitle } from './useDashboardWelcomeTitle';
import { useDoctorNonDoctorDashboard } from './useDoctorNonDoctorDashboard';
import { usePatientDashboard } from './usePatientDashboard';

export const dashboardModel = {
  useDoctorNonDoctorDashboard,
  usePatientDashboard,
  useDashboardItemTitle,
  useDashboardPatientViewEmptyState,
  useDashboardWelcomeTitle,
};
