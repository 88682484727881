import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import { AffiliationForDoctorModel } from './AffiliationForDoctorModel.fragment';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type DoctorJoinAffiliationVariables = Types.Exact<{
  affiliationId: Types.Scalars['String']['input'];
}>;


export type DoctorJoinAffiliation = { __typename?: 'Mutation', doctorJoinAffiliation: { __typename?: 'AffiliationForDoctorModel', affiliationId: string, doctorPortalUserId: string, name: string, startDate: string, endDate: string, isExpired: boolean, joinStatus: Types.AffiliationDoctorJoinStatus, services: Array<{ __typename?: 'ServiceModel', patientBookingRequiresRequestProposal: boolean, doctorCanCreateRequestProposal: boolean, isEnabled: boolean, baseServiceType: Types.ServiceType, title: string, description: string, id: string, price: number, durationMinutes?: number | null, providedWithinDays?: number | null, giftType: Types.ServiceGiftType, bookingProviderExtension: { __typename?: 'AffiliationBookingProviderExtensionModel', bookingType: Types.ServiceBookingType } | { __typename?: 'ClinicBookingProviderExtensionModel', bookingType: Types.ServiceBookingType } | { __typename?: 'DoctorBookingProviderExtensionModel', bookingType: Types.ServiceBookingType }, addons: Array<{ __typename?: 'ServiceAddonModel', id: string, type: Types.ServiceAddonType, price: number, providedWithinDays?: number | null }> }> } };


export const DoctorJoinAffiliationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DoctorJoinAffiliation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"affiliationId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"doctorJoinAffiliation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"affiliationId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"affiliationId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AffiliationForDoctorModel"}}]}}]}},...AffiliationForDoctorModel.definitions]} as unknown as DocumentNode;
export type DoctorJoinAffiliationMutationFn = Apollo.MutationFunction<DoctorJoinAffiliation, DoctorJoinAffiliationVariables>;

/**
 * __useDoctorJoinAffiliation__
 *
 * To run a mutation, you first call `useDoctorJoinAffiliation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDoctorJoinAffiliation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [doctorJoinAffiliation, { data, loading, error }] = useDoctorJoinAffiliation({
 *   variables: {
 *      affiliationId: // value for 'affiliationId'
 *   },
 * });
 */
export function useDoctorJoinAffiliation(baseOptions?: ApolloReactHooks.MutationHookOptions<DoctorJoinAffiliation, DoctorJoinAffiliationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DoctorJoinAffiliation, DoctorJoinAffiliationVariables>(DoctorJoinAffiliationDocument, options);
      }
export type DoctorJoinAffiliationHookResult = ReturnType<typeof useDoctorJoinAffiliation>;
export type DoctorJoinAffiliationMutationResult = Apollo.MutationResult<DoctorJoinAffiliation>;
export type DoctorJoinAffiliationMutationOptions = Apollo.BaseMutationOptions<DoctorJoinAffiliation, DoctorJoinAffiliationVariables>;