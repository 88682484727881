import { AffiliationForDoctorModel } from '__generated__/types';
import { useDoctorAffiliations } from 'features/Affiliations/graphql/__generated__/DoctorAffiliations.query';
import { useDoctorAffiliationsToPrompt } from 'features/Affiliations/graphql/__generated__/DoctorAffiliationsToPrompt.query';

interface IUseSortedAffiliationsReturn {
  affiliations?: AffiliationForDoctorModel[];
  loading: boolean;
}

export const useSortedAffiliations = (): IUseSortedAffiliationsReturn => {
  const { data: affiliationsToPromptData, loading: affiliationsToPromptLoading } = useDoctorAffiliationsToPrompt({
    fetchPolicy: 'cache-first',
  });

  const originalAffiliationsIds: string[] = (affiliationsToPromptData?.doctorAffiliationsToPrompt || []).map(
    affiliation => affiliation.affiliationId
  );

  const sortById = (a: AffiliationForDoctorModel, b: AffiliationForDoctorModel): number =>
    a.affiliationId < b.affiliationId ? -1 : 1;

  const { data, loading: affiliationsLoading } = useDoctorAffiliations({
    variables: {
      filterBy: {
        affiliationIdsFilter: originalAffiliationsIds,
      },
    },
  });

  const affiliations = data?.doctorAffiliations.items.sort(sortById);

  return {
    affiliations,
    loading: affiliationsToPromptLoading || affiliationsLoading,
  };
};
