import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  subSectionWrapper: {
    minHeight: 100,
    maxHeight: 116,
    border: `1px solid ${theme.palette.lines.main}`,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 8,
    overflowY: 'scroll',
  },

  root: {
    margin: '0 8px 8px 0',
    paddingLeft: 16,
    backgroundColor: theme.palette.background.default,
  },

  label: {
    color: theme.palette.generic.dark,
    fontSize: 14,
    lineHeight: '20px',
  },

  deleteIcon: {
    '&.MuiChip-deleteIcon': {
      color: theme.palette.generic.dark,
      width: 14,
      height: 14,
      fontSize: 14,
    },
  },

  fieldInputWrapper: {
    flexGrow: 1,
  },

  fieldInput: {
    all: 'unset',
    lineHeight: 1.5,
    width: '100%',
    '& .MuiInputBase-input': {
      padding: 0,
    },
  },
}));
