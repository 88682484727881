import { Portals } from '@lib/features-bll';
import { iif } from '@lib/utils';

import { DashboardSubRoutes } from '../config';

import { GlobalSearchDetailsTabType } from 'features/GlobalSearchDetails';
import { usePortalSlug } from 'features/Portal';
import { useRootModelPath } from 'features/Root/model';
import { i18n } from 'i18n';
import { NEW_ENTITY_PARAM, ROUTE_PATHS } from 'routes/constants';

interface IUseDashboardPatientViewEmptyStateParams {
  activeItem: DashboardSubRoutes;
}

interface IUseDashboardPatientViewEmptyStateReturn {
  title: string;
  body: string[];
  actions: { label: string; onClick: VoidFunction }[];
}

export const useDashboardPatientViewEmptyState = ({
  activeItem,
}: IUseDashboardPatientViewEmptyStateParams): IUseDashboardPatientViewEmptyStateReturn => {
  const portalSlug = usePortalSlug();
  const { makeGoToPath } = useRootModelPath();

  if (activeItem === DashboardSubRoutes.CHATS_ONE_TO_ONE) {
    return {
      title: i18n.t('features.dashboard.emptyState.patient.oneToOneChats.title', { context: portalSlug }),
      body: i18n.t('features.dashboard.emptyState.patient.oneToOneChats.body', {
        returnObjects: true,
        context: portalSlug,
      }) as string[],
      actions: [
        ...iif(portalSlug !== Portals.BRAVOMED, {
          label: i18n.t('features.dashboard.emptyState.patient.oneToOneChats.action', { context: portalSlug }),
          onClick: makeGoToPath({
            path: ROUTE_PATHS.searchByTabType,
            pathParams: { activeTab: GlobalSearchDetailsTabType.PRIVATE_DOCTORS },
          }),
        }),
      ],
    };
  }

  if (activeItem === DashboardSubRoutes.CHATS_CLINIC) {
    return {
      title: i18n.t('features.dashboard.emptyState.patient.clinicChats.title', { context: portalSlug }),
      body: i18n.t('features.dashboard.emptyState.patient.clinicChats.body', {
        returnObjects: true,
        context: portalSlug,
      }) as string[],
      actions: [
        ...iif(portalSlug !== Portals.BRAVOMED, {
          label: i18n.t('features.dashboard.emptyState.patient.clinicChats.action', { context: portalSlug }),
          onClick: makeGoToPath({
            path: ROUTE_PATHS.searchByTabType,
            pathParams: {
              activeTab: GlobalSearchDetailsTabType.CLINICS,
            },
          }),
        }),
      ],
    };
  }

  if (activeItem === DashboardSubRoutes.INFO) {
    return {
      title: i18n.t('features.dashboard.emptyState.patient.info.title', { context: portalSlug }),
      body: i18n.t('features.dashboard.emptyState.patient.info.body', {
        returnObjects: true,
        context: portalSlug,
      }) as string[],
      actions: [
        ...iif(portalSlug !== Portals.BRAVOMED, {
          label: i18n.t('features.dashboard.emptyState.patient.info.action', { context: portalSlug }),
          onClick: makeGoToPath({
            path: ROUTE_PATHS.searchByTabType,
            pathParams: { activeTab: GlobalSearchDetailsTabType.PRIVATE_DOCTORS },
          }),
        }),
      ],
    };
  }

  if (activeItem === DashboardSubRoutes.SURVEYS) {
    return {
      title: i18n.t('features.dashboard.emptyState.patient.surveys.title', { context: portalSlug }),
      body: i18n.t('features.dashboard.emptyState.patient.surveys.body', {
        returnObjects: true,
        context: portalSlug,
      }) as string[],
      actions: [],
    };
  }

  if (activeItem === DashboardSubRoutes.REMINDERS) {
    return {
      title: i18n.t('features.dashboard.emptyState.patient.reminders.title', { context: portalSlug }),
      body: i18n.t('features.dashboard.emptyState.patient.reminders.body', {
        returnObjects: true,
        context: portalSlug,
      }) as string[],
      actions: [
        {
          label: i18n.t('features.dashboard.emptyState.patient.reminders.action', { context: portalSlug }),
          onClick: makeGoToPath({ path: ROUTE_PATHS.reminder, pathParams: { reminderId: NEW_ENTITY_PARAM } }),
        },
      ],
    };
  }

  if (activeItem === DashboardSubRoutes.CARE_TEAM) {
    return {
      title: i18n.t('features.dashboard.emptyState.patient.careTeam.title', { context: portalSlug }),
      body: i18n.t('features.dashboard.emptyState.patient.careTeam.body', {
        returnObjects: true,
        context: portalSlug,
      }) as string[],
      actions: [
        ...iif(portalSlug !== Portals.BRAVOMED, {
          label: i18n.t('features.dashboard.emptyState.patient.careTeam.action', { context: portalSlug }),
          onClick: makeGoToPath({
            path: ROUTE_PATHS.searchByTabType,
            pathParams: { activeTab: GlobalSearchDetailsTabType.PRIVATE_DOCTORS },
          }),
        }),
      ],
    };
  }

  return {
    title: '',
    body: [],
    actions: [],
  };
};
