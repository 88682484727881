import { useEffect, useState } from 'react';

import { AllowDenyPolicyType } from '__generated__/types';
import { ActiveChatOrRequestType } from 'features/Chat/config';
import { useChatModelChatPatientWritePolicy } from 'features/Chat/model';
import { useUserModelStore } from 'features/Users/model';

interface IUseDoctorModelWriteToDoctorVisibilityProps {
  defaultDoctorPolicy?: AllowDenyPolicyType;
  patientWriteButtonVisibilityPolicy?: AllowDenyPolicyType;
  doctorPortalUserId?: string;
  isListing?: boolean;
}

interface IUseDoctorModelWriteToDoctorVisibilityReturn {
  isWriteToDoctorButtonVisible: boolean;
  isCareTeamWriteToDoctorButtonVisible: boolean;
}

export const useDoctorModelWriteToDoctorButtonVisibility = ({
  defaultDoctorPolicy,
  patientWriteButtonVisibilityPolicy,
  doctorPortalUserId,
  isListing,
}: IUseDoctorModelWriteToDoctorVisibilityProps): IUseDoctorModelWriteToDoctorVisibilityReturn => {
  const { isPatient } = useUserModelStore();
  const getChatPolicy = useChatModelChatPatientWritePolicy();
  const [isWriteToDoctorButtonVisible, setIsWriteToDoctorButtonVisible] = useState<boolean>(false);
  const [isCareTeamWriteToDoctorButtonVisible, setIsCareTeamWriteToDoctorButtonVisible] = useState<boolean>(false);

  useEffect(() => {
    const getWriteToDoctorVisibility = async (): Promise<void> => {
      const isWriteToDoctorFeatureEnabled =
        defaultDoctorPolicy === AllowDenyPolicyType.ALLOW &&
        patientWriteButtonVisibilityPolicy === AllowDenyPolicyType.ALLOW;

      if (!isWriteToDoctorFeatureEnabled) return;

      if (!isPatient) {
        setIsWriteToDoctorButtonVisible(true);
        setIsCareTeamWriteToDoctorButtonVisible(true);

        return;
      }

      if (doctorPortalUserId) {
        const { isPatientBlocked, activeChatOrRequest } = await getChatPolicy({
          portalUserId: doctorPortalUserId,
        });

        setIsWriteToDoctorButtonVisible(isListing ? !isPatientBlocked : !isPatientBlocked && !activeChatOrRequest);
        setIsCareTeamWriteToDoctorButtonVisible(
          !isPatientBlocked &&
            (activeChatOrRequest === ActiveChatOrRequestType.REQUEST ||
              activeChatOrRequest === ActiveChatOrRequestType.CHAT)
        );
      }
    };

    getWriteToDoctorVisibility();
  }, [defaultDoctorPolicy, patientWriteButtonVisibilityPolicy, isPatient, doctorPortalUserId, isListing]);

  return { isWriteToDoctorButtonVisible, isCareTeamWriteToDoctorButtonVisible };
};
