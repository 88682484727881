import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DashboardSubRoutes } from '../config';
import { IDashboardUrlParams } from '../types';

import { usePortalConfiguration } from 'features/Portal';
import { i18n } from 'i18n';

interface IUseDashboardItemTitleParams {
  getItemCount?: (item: DashboardSubRoutes) => number;
}

interface IUseDashboardItemTitleReturn {
  activeItem?: DashboardSubRoutes;
  title: string | null;
}

export const useDashboardItemTitle = ({ getItemCount }: IUseDashboardItemTitleParams): IUseDashboardItemTitleReturn => {
  const { activeItem } = useParams<IDashboardUrlParams>();

  const [title, setTitle] = useState<IUseDashboardItemTitleReturn['title']>(null);

  const isClinicsEnabled = !!usePortalConfiguration()?.isClinicsEnabled;

  const DASHBOARD_TITLES = {
    [DashboardSubRoutes.CHATS]: null,
    [DashboardSubRoutes.CHATS_ONE_TO_ONE]: isClinicsEnabled
      ? 'features.dashboard.title.oneToOneChatsTitle'
      : 'features.dashboard.title.chatsTitle',
    [DashboardSubRoutes.CHATS_CLINIC]: 'features.dashboard.title.clinicChatsTitle',
    [DashboardSubRoutes.INFO]: 'features.dashboard.title.infoTitle',
    [DashboardSubRoutes.SURVEYS]: 'features.dashboard.title.surveysTitle',
    [DashboardSubRoutes.REMINDERS]: 'features.dashboard.title.remindersTitle',
    [DashboardSubRoutes.INTRAMED]: 'features.dashboard.title.intramedTitle',
    [DashboardSubRoutes.CARE_TEAM]: 'features.dashboard.title.careTeamTitle',
  };

  useEffect(() => {
    if (getItemCount) {
      const i18nKey = DASHBOARD_TITLES[activeItem];
      const count = getItemCount(activeItem);
      const newTitle = i18nKey && count != null ? i18n.t(i18nKey, { count }) : null;
      setTitle(newTitle);
    }
  }, [activeItem, getItemCount]);

  return {
    activeItem,
    title,
  };
};
