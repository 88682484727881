import { ChatsServiceTypeFilter, ChatsStatusFilter, IChatsListingFilters } from '@lib/features-bll';
import {
  IFilterChipItem,
  OnDeleteChipHandler,
  SelectItem,
  useFilterQueryParams,
  useSearchQueryParams,
} from '@lib/react-components';
import { iif } from '@lib/utils';

import { GsdHsrClinicSubType, Maybe, ServiceBookingType, ChatType } from '__generated__/types';
import { useUserModelStore } from 'features/Users/model';
import { i18n } from 'i18n';
import { useIsHSRIntegratedPortal } from 'lib/hooks/useIsHSRIntegratedPortal';

enum SelectableFilterType {
  ServiceType = 'service',
  BookingType = 'booking',
  GsdHsrClinicType = 'clinic',
  Status = 'status',
  Assigned = 'assigned',
}

type BookingType = Exclude<ServiceBookingType, ServiceBookingType.CLINIC>;

type SetMultipleFilterFn = (values: string[]) => void;

interface IUseChatModelChatListingFiltersParams {
  chatType: ChatType;
}

interface IUseChatModelChatListingFiltersReturn {
  freeTextFilter: string;
  setFreeTextFilter: (value: string) => void;

  showServiceTypeFilter: boolean;
  serviceTypeFilter: ChatsServiceTypeFilter[];
  setServiceTypeFilter: SetMultipleFilterFn;
  serviceTypeItems: SelectItem[];
  serviceTypeChips: IFilterChipItem[];

  showBookingTypeFilter: boolean;
  bookingTypeFilter: BookingType[];
  setBookingTypeFilter: SetMultipleFilterFn;
  bookingTypeItems: SelectItem[];
  bookingTypeChips: IFilterChipItem[];

  showGsdHsrClinicTypeFilter: boolean;
  gsdHsrClinicTypeFilter: GsdHsrClinicSubType[];
  setGsdHsrClinicTypeFilter: SetMultipleFilterFn;
  gsdHsrClinicTypeItems: SelectItem[];
  gsdHsrClinicTypeChips: IFilterChipItem[];

  showStatusFilter: boolean;
  statusFilter: ChatsStatusFilter[];
  setStatusFilter: SetMultipleFilterFn;
  statusItems: SelectItem[];
  statusChips: IFilterChipItem[];

  showAssignedFilter: boolean;
  assignedFilter: boolean;
  setAssignedFilter: (value: boolean) => void;

  currentFilters: IChatsListingFilters;
  anyFiltersApplied: boolean;
  selectableFiltersAppliedCount: number;
  resetSelectableFiltersDisabled: boolean;
  resetSelectableFilters: VoidFunction;

  removeChip: OnDeleteChipHandler;
}

const queryParamsToFilter = <T>(queryParams: Maybe<string>): T[] =>
  typeof queryParams !== 'string' || queryParams === '' ? [] : (queryParams.split(',') as T[]);

const formatSelectItem = (labels: Record<string, string>, value: string) => ({ label: labels[value], value });

const formatChips = <T extends string>(
  name: SelectableFilterType,
  labels: Record<T, string>,
  showFilter: boolean,
  filter: T[]
) =>
  showFilter
    ? filter.map(value => ({
        filterName: name,
        filterValue: value,
        chipLabel: labels[value],
      }))
    : [];

export const useChatModelChatListingFilters = ({
  chatType,
}: IUseChatModelChatListingFiltersParams): IUseChatModelChatListingFiltersReturn => {
  const isHSRIntegratedPortal = useIsHSRIntegratedPortal();
  const { activeProfile, isDoctor } = useUserModelStore();

  const { search: freeTextFilter, setSearch: setFreeTextFilter } = useSearchQueryParams();

  const isOneToOne = chatType === ChatType.ONE_TO_ONE;
  const isClinic = chatType === ChatType.CLINIC;

  const {
    filters: selectableFilters,
    setFilter: setSelectableFilter,
    setMultipleFilters: setMultipleSelectableFilter,
  } = useFilterQueryParams<Record<SelectableFilterType, Maybe<string | string[]>>>({
    [SelectableFilterType.ServiceType]: null,
    [SelectableFilterType.BookingType]: null,
    [SelectableFilterType.GsdHsrClinicType]: null,
    [SelectableFilterType.Status]: null,
    [SelectableFilterType.Assigned]: null,
  });

  const serviceTypeLabels = {
    [ChatsServiceTypeFilter.VIDEO_VISIT]: i18n.t('features.chat.filters.service.videovisit'),
    [ChatsServiceTypeFilter.CONSULTATION]: i18n.t('features.chat.filters.service.consultation'),
    [ChatsServiceTypeFilter.CHAT]: i18n.t('features.chat.filters.service.chat'),
  };

  const bookingTypeLabels = {
    [ServiceBookingType.DOCTOR]: i18n.t('features.chat.filters.booking.private'),
    [ServiceBookingType.AFFILIATION]: i18n.t('features.chat.filters.booking.affiliated'),
  };

  const gsdHsrClinicTypeLabels = {
    [GsdHsrClinicSubType.GENERIC]: i18n.t('features.chat.filters.gsdHsrClinic.private'),
    [GsdHsrClinicSubType.NON_INTEGRATED]: i18n.t('features.chat.filters.gsdHsrClinic.free'),
    [GsdHsrClinicSubType.SSN]: i18n.t('features.chat.filters.gsdHsrClinic.ssn'),
  };

  const statusLabels = {
    [ChatsStatusFilter.UNASSIGNED]: i18n.t('features.chat.filters.status.unassigned'),
    [ChatsStatusFilter.ACTIVE]: i18n.t('features.chat.filters.status.open'),
    [ChatsStatusFilter.CLOSE_DUE]: i18n.t('features.chat.filters.status.expiring'),
    [ChatsStatusFilter.PAST_DUE]: i18n.t('features.chat.filters.status.expired'),
    [ChatsStatusFilter.PENDING_PAYMENT]: i18n.t('features.chat.filters.status.pendingPayment'),
    [ChatsStatusFilter.CLOSED]: i18n.t('features.chat.filters.status.closed'),
  };

  const showServiceTypeFilter = isDoctor;
  const serviceTypeFilter = queryParamsToFilter<ChatsServiceTypeFilter>(
    selectableFilters[SelectableFilterType.ServiceType] as Maybe<string>
  );
  const setServiceTypeFilter: SetMultipleFilterFn = values =>
    setSelectableFilter(SelectableFilterType.ServiceType, values);

  const showBookingTypeFilter = isDoctor && isOneToOne;
  const bookingTypeFilter = queryParamsToFilter<BookingType>(
    selectableFilters[SelectableFilterType.BookingType] as Maybe<string>
  );
  const setBookingTypeFilter: SetMultipleFilterFn = values =>
    setSelectableFilter(SelectableFilterType.BookingType, values);

  const showGsdHsrClinicTypeFilter = isDoctor && isClinic && isHSRIntegratedPortal;
  const gsdHsrClinicTypeFilter = queryParamsToFilter<GsdHsrClinicSubType>(
    selectableFilters[SelectableFilterType.GsdHsrClinicType] as Maybe<string>
  );
  const setGsdHsrClinicTypeFilter: SetMultipleFilterFn = values =>
    setSelectableFilter(SelectableFilterType.GsdHsrClinicType, values);

  const showStatusFilter = isDoctor;
  const statusFilter = queryParamsToFilter<ChatsStatusFilter>(
    selectableFilters[SelectableFilterType.Status] as Maybe<string>
  );
  const setStatusFilter: SetMultipleFilterFn = values => setSelectableFilter(SelectableFilterType.Status, values);

  const showAssignedFilter = isDoctor && isClinic;
  const assignedFilter = !!selectableFilters[SelectableFilterType.Assigned];
  const setAssignedFilter = (value: boolean) =>
    setSelectableFilter(SelectableFilterType.Assigned, value ? 'true' : null);

  const serviceTypeApplied = !!(showServiceTypeFilter && serviceTypeFilter.length);
  const bookingTypeApplied = !!(showBookingTypeFilter && bookingTypeFilter.length);
  const gsdHsrClinicTypeApplied = !!(showGsdHsrClinicTypeFilter && gsdHsrClinicTypeFilter.length);
  const statusApplied = !!(showStatusFilter && statusFilter.length);
  const assignedApplied = !!(showAssignedFilter && assignedFilter);
  const anySelectableFilters =
    serviceTypeApplied || bookingTypeApplied || gsdHsrClinicTypeApplied || statusApplied || assignedApplied;

  const resetSelectableFilters = () => {
    setMultipleSelectableFilter({
      [SelectableFilterType.ServiceType]: [],
      [SelectableFilterType.BookingType]: [],
      [SelectableFilterType.GsdHsrClinicType]: [],
      [SelectableFilterType.Status]: [],
      [SelectableFilterType.Assigned]: null,
    });
  };

  const removeChip: OnDeleteChipHandler = (filterName, filterValue): void => {
    if (filterName === SelectableFilterType.ServiceType) {
      setServiceTypeFilter(serviceTypeFilter.filter(value => value !== filterValue));
    }

    if (filterName === SelectableFilterType.BookingType) {
      setBookingTypeFilter(bookingTypeFilter.filter(value => value !== filterValue));
    }

    if (filterName === SelectableFilterType.GsdHsrClinicType) {
      setGsdHsrClinicTypeFilter(serviceTypeFilter.filter(value => value !== filterValue));
    }

    if (filterName === SelectableFilterType.Status) {
      setStatusFilter(statusFilter.filter(value => value !== filterValue));
    }
  };

  return {
    freeTextFilter,
    setFreeTextFilter,

    showServiceTypeFilter,
    serviceTypeFilter,
    setServiceTypeFilter,
    serviceTypeItems: [
      formatSelectItem(serviceTypeLabels, ChatsServiceTypeFilter.VIDEO_VISIT),
      formatSelectItem(serviceTypeLabels, ChatsServiceTypeFilter.CONSULTATION),
      formatSelectItem(serviceTypeLabels, ChatsServiceTypeFilter.CHAT),
    ],
    serviceTypeChips: formatChips<ChatsServiceTypeFilter>(
      SelectableFilterType.ServiceType,
      serviceTypeLabels,
      showServiceTypeFilter,
      serviceTypeFilter
    ),

    showBookingTypeFilter,
    bookingTypeFilter,
    setBookingTypeFilter,
    bookingTypeItems: [
      formatSelectItem(bookingTypeLabels, ServiceBookingType.DOCTOR),
      formatSelectItem(bookingTypeLabels, ServiceBookingType.AFFILIATION),
    ],
    bookingTypeChips: formatChips<BookingType>(
      SelectableFilterType.BookingType,
      bookingTypeLabels,
      showBookingTypeFilter,
      bookingTypeFilter
    ),

    showGsdHsrClinicTypeFilter,
    gsdHsrClinicTypeFilter,
    setGsdHsrClinicTypeFilter,
    gsdHsrClinicTypeItems: [
      formatSelectItem(gsdHsrClinicTypeLabels, GsdHsrClinicSubType.GENERIC),
      formatSelectItem(gsdHsrClinicTypeLabels, GsdHsrClinicSubType.NON_INTEGRATED),
      formatSelectItem(gsdHsrClinicTypeLabels, GsdHsrClinicSubType.SSN),
    ],
    gsdHsrClinicTypeChips: formatChips<GsdHsrClinicSubType>(
      SelectableFilterType.GsdHsrClinicType,
      gsdHsrClinicTypeLabels,
      showGsdHsrClinicTypeFilter,
      gsdHsrClinicTypeFilter
    ),

    showStatusFilter,
    statusFilter,
    setStatusFilter,
    statusItems: [
      ...iif(isClinic, formatSelectItem(statusLabels, ChatsStatusFilter.UNASSIGNED)),
      formatSelectItem(statusLabels, ChatsStatusFilter.ACTIVE),
      formatSelectItem(statusLabels, ChatsStatusFilter.CLOSE_DUE),
      formatSelectItem(statusLabels, ChatsStatusFilter.PAST_DUE),
      formatSelectItem(statusLabels, ChatsStatusFilter.PENDING_PAYMENT),
      formatSelectItem(statusLabels, ChatsStatusFilter.CLOSED),
    ],
    statusChips: formatChips<ChatsStatusFilter>(
      SelectableFilterType.Status,
      statusLabels,
      showStatusFilter,
      statusFilter
    ),

    showAssignedFilter,
    assignedFilter,
    setAssignedFilter,

    currentFilters: {
      ...(freeTextFilter && { freeText: freeTextFilter }),
      ...(showServiceTypeFilter && serviceTypeFilter && { serviceTypes: serviceTypeFilter }),
      ...(showBookingTypeFilter && bookingTypeFilter && { serviceBookingTypes: bookingTypeFilter }),
      ...(showGsdHsrClinicTypeFilter && gsdHsrClinicTypeFilter && { gsdHsrClinicSubType: gsdHsrClinicTypeFilter }),
      ...(showStatusFilter && statusFilter && { statuses: statusFilter }),
      ...(showAssignedFilter && assignedFilter && { assignedDoctorPortalUserId: activeProfile?.portalUser?.id }),
    },

    anyFiltersApplied: !!freeTextFilter || anySelectableFilters,
    selectableFiltersAppliedCount: [
      serviceTypeApplied,
      bookingTypeApplied,
      gsdHsrClinicTypeApplied,
      statusApplied,
      assignedApplied,
    ].filter(applied => applied).length,
    resetSelectableFiltersDisabled: !anySelectableFilters,
    resetSelectableFilters,
    removeChip,
  };
};
