import { useEffect, useRef } from 'react';

import { useRootModelAffiliationsModalState } from './useRootModelAffiliationsModalState';
import { useRootModelNativePatientAppModalState } from './useRootModelNativePatientAppModalState';
import { useRootModelNativeProAppModalState } from './useRootModelNativeProAppModalState';
import { useRootModelPatientInfoModal } from './useRootModelPatientInfoModal';
import { useRootModelPrivaciesModalState } from './useRootModelPrivaciesModalState';
import { useRootModelResidenceAddressModalState } from './useRootModelResidenceAddressModalState';
import { useRootModelSphereOfInterestModalState } from './useRootModelSphereOfInterestModalState';
import { useRootModelUpdateNoticeModalState } from './useRootModelUpdateNoticeModalState';

import { Maybe } from '__generated__/types';
import { UpdateNotice } from 'features/UpdateNotice/types';
import { useUserModelStore } from 'features/Users/model';
import { useMatchRoutes } from 'lib/hooks/useMatchRoutes';
import { ROUTE_PATHS } from 'routes/constants';

interface IModalsPriorityListItem {
  id: string;
  condition: boolean;
  open: VoidFunction;
  close: VoidFunction;
  showOnRoutes?: string[];
  hideOnRoutes?: string[];
}

interface IUseGlobalModalsQueueReturn {
  doctorSphereOfInterest: string[];
  notice?: Maybe<UpdateNotice>;
  isPrivaciesModalOpened: boolean;
  isAffiliationsModalOpened: boolean;
  isSphereOfInterestModalOpened: boolean;
  isUpdateResidenceAddressModalOpened: boolean;
  isSetupNativeProAppModalOpened: boolean;
  isSetupNativePatientAppModalOpened: boolean;
  isPatientInfoModalOpened: boolean;
  isUpdateNoticeModalOpened: boolean;
  handleNextModal: VoidFunction;
}

export const useRootModelGlobalModalsQueue = (): IUseGlobalModalsQueueReturn => {
  const { activeProfile } = useUserModelStore();
  const { matchRoutes } = useMatchRoutes();

  const {
    isPrivaciesModalOpened,
    isPrivaciesModalAllowed,
    openPrivaciesModal,
    closePrivaciesModal,
    isPrivaciesLoading,
  } = useRootModelPrivaciesModalState();

  const { isUpdateNoticeModalOpened, openUpdateNoticeModal, closeUpdateNoticeModal, notice, isNoticeLoading } =
    useRootModelUpdateNoticeModalState();

  const {
    isAffiliationsModalOpened,
    isAffiliationsModalAllowed,
    openAffiliationsModal,
    closeAffiliationsModal,
    isAffiliationsLoading,
  } = useRootModelAffiliationsModalState();

  const {
    isUpdateResidenceAddressModalOpened,
    isUpdateResidenceAddressModalAllowed,
    openUpdateResidenceAddressModal,
    closeUpdateResidenceAddressModal,
    isResidenceAddressLoading,
  } = useRootModelResidenceAddressModalState();

  const {
    doctorSphereOfInterest,
    openSphereOfInterestModal,
    closeSphereOfInterestModal,
    isSphereOfInterestModalOpened,
    isSphereOfInterestModalAllowed,
  } = useRootModelSphereOfInterestModalState();

  const { openPatientInfoModal, closePatientInfoModal, isPatientInfoModalOpened, isPatientInfoModalAllowed } =
    useRootModelPatientInfoModal();

  const {
    isSetupNativeProAppModalOpened,
    isNativeProAppModalAllowed,
    openSetupNativeProAppModal,
    closeSetupNativeProAppModal,
  } = useRootModelNativeProAppModalState();
  const {
    isSetupNativePatientAppModalOpened,
    isNativePatientAppModalAllowed,
    openSetupNativePatientAppModal,
    closeSetupNativePatientAppModal,
  } = useRootModelNativePatientAppModalState();

  const modalsQueue = useRef<{ id: string; open: VoidFunction; close: VoidFunction; showOnRoutes?: string[] }[]>([]);

  const isModalChecksLoading =
    isPrivaciesLoading || isAffiliationsLoading || isResidenceAddressLoading || isNoticeLoading;
  const isModalAllowedChangeCheck =
    isPrivaciesModalAllowed ||
    isAffiliationsModalAllowed ||
    isSphereOfInterestModalAllowed ||
    isUpdateResidenceAddressModalAllowed ||
    isNativeProAppModalAllowed ||
    isNativePatientAppModalAllowed ||
    isPatientInfoModalAllowed ||
    notice;

  useEffect(() => {
    if (activeProfile?.hasPostRegister || isModalChecksLoading) {
      return;
    }

    modalsQueue.current = [];

    const modalsPriorityList: IModalsPriorityListItem[] = [
      {
        id: 'privacies-modal',
        condition: isPrivaciesModalAllowed,
        open: openPrivaciesModal,
        close: closePrivaciesModal,
      },
      {
        id: 'affiliations-modal',
        condition: isAffiliationsModalAllowed,
        open: openAffiliationsModal,
        close: closeAffiliationsModal,
      },
      {
        id: 'sphere-of-interest-modal',
        condition: isSphereOfInterestModalAllowed,
        open: openSphereOfInterestModal,
        close: closeSphereOfInterestModal,
      },
      {
        id: 'update-residence-address-modal',
        condition: isUpdateResidenceAddressModalAllowed,
        open: openUpdateResidenceAddressModal,
        close: closeUpdateResidenceAddressModal,
      },
      {
        id: 'native-pro-app-modal',
        condition: isNativeProAppModalAllowed,
        open: openSetupNativeProAppModal,
        close: closeSetupNativeProAppModal,
      },
      {
        id: 'native-patient-app-modal',
        condition: isNativePatientAppModalAllowed,
        open: openSetupNativePatientAppModal,
        close: closeSetupNativePatientAppModal,
      },
      {
        id: 'patient-info-modal',
        condition: isPatientInfoModalAllowed,
        open: openPatientInfoModal,
        close: closePatientInfoModal,
      },
      {
        id: 'update-notice-modal',
        condition: !!notice,
        showOnRoutes: [ROUTE_PATHS.dashboard, ROUTE_PATHS.dashboardItem, ROUTE_PATHS.dashboardCommon],
        open: openUpdateNoticeModal,
        close: closeUpdateNoticeModal,
      },
    ];

    modalsPriorityList.forEach(({ condition, open, close, showOnRoutes, id, hideOnRoutes }) => {
      const showOnRoutesCondition = showOnRoutes ? matchRoutes(showOnRoutes) : true;
      const hideOnRoutesCondition = hideOnRoutes ? matchRoutes(hideOnRoutes) : true;

      if (condition && showOnRoutesCondition && hideOnRoutesCondition) {
        modalsQueue.current.push({ id, open, close });
      }
    });
  }, [activeProfile?.hasPostRegister, isModalChecksLoading, isModalAllowedChangeCheck]);

  useEffect(() => {
    if (!isModalChecksLoading && modalsQueue.current.length) {
      modalsQueue.current[0].open();
    }
  }, [isModalChecksLoading, modalsQueue.current.length]);

  const handleNextModal = (): void => {
    const currentModal = modalsQueue.current.shift();

    if (currentModal) {
      currentModal.close();
    }
  };

  return {
    doctorSphereOfInterest,
    isPrivaciesModalOpened,
    isAffiliationsModalOpened,
    isSphereOfInterestModalOpened,
    isUpdateResidenceAddressModalOpened,
    isSetupNativeProAppModalOpened,
    isSetupNativePatientAppModalOpened,
    isPatientInfoModalOpened,
    isUpdateNoticeModalOpened,
    notice,
    handleNextModal,
  };
};
