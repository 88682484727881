import { TablePaginationProps, usePaginationQueryParams } from '@lib/react-components';
import { useDebounce } from '@lib/utils';

import { IDoctorClinicsFilters } from '../DoctorClinicsFilters/types';

import { DoctorClinicList, useDoctorClinicList } from 'features/Clinics/graphql/__generated__/DoctorClinicList.query';
import { usePortal } from 'features/Portal';
import { useUserModelStore } from 'features/Users/model';

interface IUseDoctorClinicsReturn {
  data: DoctorClinicList['doctorClinicList']['items'];
  loading: boolean;
  pagination: TablePaginationProps;
}

type UseDoctorClinics = (search: string, filters: IDoctorClinicsFilters) => IUseDoctorClinicsReturn;

export const useDoctorClinics: UseDoctorClinics = (search, filters) => {
  const { setPage, setLimit, page, limit } = usePaginationQueryParams();
  const debouncedSearch = useDebounce(search);
  const { activeProfile, isPatient } = useUserModelStore();
  const { portal } = usePortal();

  const skip = !portal?.configuration?.isClinicsEnabled || isPatient || activeProfile?.hasPostRegister;

  const { data, loading } = useDoctorClinicList({
    variables: {
      pagination: {
        page: page + 1,
        numberOfRecords: limit,
      },
      filterBy: {
        searchByFilter: debouncedSearch,
        specializationsFilter: filters.specializations,
        portalExtensionFilter: {
          gsdHsrClinicSubType: filters.types,
        },
        canBookForPatientFilter: filters.showBookableForPatient || null,
        amMemberFilter: filters.showMyClinics || null,
      },
    },
    fetchPolicy: 'cache-first',
    skip,
  });

  return {
    data: data?.doctorClinicList?.items || [],
    loading,
    pagination: {
      page,
      count: data?.doctorClinicList?.totalCount || 0,
      rowsPerPage: limit,
      onPageChange: (_, newPage): void => setPage(newPage),
      onRowsPerPageChange: ({ target: { value } }): void => setLimit(parseInt(value, 10)),
    },
  };
};
