import {
  PendingApplicationRequestUrlKeys,
  WebToNativeActions,
  usePendingApplicationRequestApiConfirmAndExecute,
} from '@lib/features-bll';
import { useQueryParams } from '@lib/react-components';
import { useEffect } from 'react';

import { useRootModelPath } from 'features/Root/model';
import { useUserModelStore } from 'features/Users/model';
import { createNativeAction, platformBasedAction } from 'lib/helpers/platform';
import { ROUTE_PATHS } from 'routes/constants';

export const useAssistedSearchModelDirectMessageFromAssistedSearchRedirect = (): string | undefined => {
  const { isPatient } = useUserModelStore();
  const { getPath, makeGoToPath } = useRootModelPath();
  const { pendingApplicationRequestId } = useQueryParams<{
    [PendingApplicationRequestUrlKeys.pendingApplicationRequestId]: string;
  }>();

  const [confirmAndExecuteRequest, { data }] = usePendingApplicationRequestApiConfirmAndExecute({
    variables: { pendingApplicationRequestId },
    onCompleted: ({ confirmAndExecutePendingApplicationRequest }) => {
      if (confirmAndExecutePendingApplicationRequest.__typename === 'BasicChatReferenceModel') {
        return platformBasedAction({
          webAction: makeGoToPath({
            path: ROUTE_PATHS.chatOneToOne,
            pathParams: { chatId: confirmAndExecutePendingApplicationRequest.chatId },
          }),
          nativeAction: createNativeAction(WebToNativeActions.openOneToOneChat, {
            id: confirmAndExecutePendingApplicationRequest.chatId,
          }),
        });
      }

      return undefined;
    },
  });

  useEffect(() => {
    if (isPatient && pendingApplicationRequestId) confirmAndExecuteRequest();
  }, [isPatient, pendingApplicationRequestId]);

  return data?.confirmAndExecutePendingApplicationRequest.__typename === 'BasicChatReferenceModel' &&
    data.confirmAndExecutePendingApplicationRequest.chatId
    ? getPath({
        path: ROUTE_PATHS.chatOneToOne,
        pathParams: { chatId: data.confirmAndExecutePendingApplicationRequest.chatId },
      })
    : undefined;
};
