import { PersistedDataKeys } from '@lib/core';
import { Redirect, useLocation } from 'react-router-dom';

import { useRootModelPath } from 'features/Root/model';
import { persistedData } from 'lib/helpers/persistedData';
import { DEFAULT_AUTHORIZED_ROUTE, DEFAULT_UNAUTHORIZED_ROUTE } from 'routes/constants';

export const RootEmptyPortalRouteRedirect = (): JSX.Element => {
  const { getPath } = useRootModelPath();
  const { search } = useLocation();
  const accessToken = persistedData.get<string>(PersistedDataKeys.AccessToken);

  return (
    <Redirect to={getPath({ path: accessToken ? DEFAULT_AUTHORIZED_ROUTE : DEFAULT_UNAUTHORIZED_ROUTE, search })} />
  );
};
