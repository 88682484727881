import { hasMandatoryAddressDetailsFields, IHiddenValues } from '@lib/react-components';
import { FormikErrors, FormikTouched } from 'formik';
import omit from 'lodash-es/omit';
import { useState } from 'react';

import { i18n } from 'i18n';
import { isFutureDate, isPreviousDate } from 'lib/helpers/date';
import { isDateValidFormat } from 'lib/helpers/validation/dateValidation';

export const getFormErrors =
  <T>(errors: FormikErrors<T>, touched: FormikTouched<T>) =>
  <U extends keyof T>(name: U): FormikErrors<T>[U] =>
    errors[name] && touched[name] && errors[name];

export const useDateErrors = (anyDate = false, onlyFutureDate = false) => {
  const wrongInputDateFormat = i18n.t('errors.dates.wrongFormat');
  const [dateErrorText, setDateErrorText] = useState<string>('');
  const [evaluationDateIsDirty, setEvaluationDateIsDirty] = useState<boolean>(false);

  const setEvaluationError = (value: string): void => {
    const setWrongInputDateFormatText = !isDateValidFormat(value) && wrongInputDateFormat;

    if (anyDate) {
      setDateErrorText(setWrongInputDateFormatText || '');
    } else if (onlyFutureDate) {
      setDateErrorText(
        setWrongInputDateFormatText || (isPreviousDate(value) && i18n.t('errors.dates.dateInPast')) || ''
      );
    } else {
      setDateErrorText(
        setWrongInputDateFormatText || (isFutureDate(value) && i18n.t('errors.dates.dateInFuture')) || ''
      );
    }
  };

  const handleDateBlur = (value: string): void => {
    setEvaluationDateIsDirty(true);
    setEvaluationError(value);
  };

  const handleDateChange = (value: string): void => {
    if (evaluationDateIsDirty) {
      setEvaluationError(value);
    }
  };

  return {
    dateErrorText,
    handleDateBlur,
    handleDateChange,
  };
};

export const useResidenceAddressError = () => {
  const [residenceAddressErrorText, setResidenceAddressErrorText] = useState<string>('');

  const setResidenceAddressError = (value: string, hiddenValues?: IHiddenValues): void => {
    const addressDetailsFields = omit(hiddenValues, 'region', 'placeId');

    if (!value) {
      setResidenceAddressErrorText(i18n.t('errors.residenceAddress.empty'));
    } else if (hiddenValues && !hasMandatoryAddressDetailsFields(addressDetailsFields)) {
      setResidenceAddressErrorText(`${i18n.t('components.forms.registrationForm.validationAdvice.addressDetails')}`);
    } else {
      setResidenceAddressErrorText('');
    }
  };

  const handleResidenceAddressErrorsBlur = (value: string, hiddenValues?: IHiddenValues): void => {
    setResidenceAddressError(value, hiddenValues);
  };

  const handleResidenceAddressErrorsChange = (value: string, hiddenValues?: IHiddenValues): void => {
    setResidenceAddressError(value, hiddenValues);
  };

  return {
    residenceAddressErrorText,
    handleResidenceAddressErrorsChange,
    handleResidenceAddressErrorsBlur,
  };
};

export const useMandatoryFieldError = () => {
  const [errorText, setErrorText] = useState<string>('');

  const handleErrorText = (value: string): void =>
    setErrorText(value ? '' : i18n.t('general.validation.mandatoryField'));

  const handleFieldBlur = (value: string): void => {
    handleErrorText(value);
  };

  const handleFieldChange = (value: string): void => {
    handleErrorText(value);
  };

  return {
    errorText,
    handleFieldBlur,
    handleFieldChange,
  };
};
