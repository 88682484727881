import { GlobalSearchDetailsAccordion } from './GlobalSearchDetailsAccordion';
import { GlobalSearchDetailsAccordions } from './GlobalSearchDetailsAccordions';
import { GlobalSearchDetailsAffiliatedDoctorTab } from './GlobalSearchDetailsAffiliatedDoctorTab';
import { GlobalSearchDetailsCardCv } from './GlobalSearchDetailsCardCv';
import { GlobalSearchDetailsCardDetailsWrapper } from './GlobalSearchDetailsCardDetailsWrapper';
import { GlobalSearchDetailsCardDoctor } from './GlobalSearchDetailsCardDoctor';
import { GlobalSearchDetailsCardDoctorAvatar } from './GlobalSearchDetailsCardDoctorAvatar';
import { GlobalSearchDetailsCardObject } from './GlobalSearchDetailsCardObject';
import { GlobalSearchDetailsCardProfileLink } from './GlobalSearchDetailsCardProfileLink';
import { GlobalSearchDetailsCardServiceChip } from './GlobalSearchDetailsCardServiceChip';
import { GlobalSearchDetailsCardSphereOfInterest } from './GlobalSearchDetailsCardSphereOfInterest';
import { GlobalSearchDetailsCardWrapper } from './GlobalSearchDetailsCardWrapper';
import { GlobalSearchDetailsClinicsTab } from './GlobalSearchDetailsClinicsTab';
import { GlobalSearchDetailsContactsLabel } from './GlobalSearchDetailsContactsLabel';
import { GlobalSearchDetailsDoctorTab } from './GlobalSearchDetailsDoctorTab';
import { GlobalSearchDetailsFilterChips } from './GlobalSearchDetailsFilterChips';
import { GlobalSearchDetailsFilterFieldMobile } from './GlobalSearchDetailsFilterFieldMobile';
import { GlobalSearchDetailsFilters } from './GlobalSearchDetailsFilters';
import { GlobalSearchDetailsFiltersModal } from './GlobalSearchDetailsFiltersModal';
import { GlobalSearchDetailsFiltersSection } from './GlobalSearchDetailsFiltersSection';
import { GlobalSearchDetailsFiltersSectionMobile } from './GlobalSearchDetailsFiltersSectionMobile';
import { GlobalSearchDetailsFreeClinicsTab } from './GlobalSearchDetailsFreeClinicsTab';
import { GlobalSearchDetailsList } from './GlobalSearchDetailsList';
import { GlobalSearchDetailsListTabs } from './GlobalSearchDetailsListTabs';
import { GlobalSearchDetailsListWrapper } from './GlobalSearchDetailsListWrapper';
import { GlobalSearchDetailsNoResult } from './GlobalSearchDetailsNoResult';
import { GlobalSearchDetailsPageWrapper } from './GlobalSearchDetailsPageWrapper';
import { GlobalSearchDetailsResultSection } from './GlobalSearchDetailsResultSection';
import { GlobalSearchDetailsResultTitle } from './GlobalSearchDetailsResultTitle';
import { GlobalSearchDetailsScrollTopBottomButton } from './GlobalSearchDetailsScrollTopBottomButton';
import { GlobalSearchDetailsSpecificSearchFilter } from './GlobalSearchDetailsSpecificSearchFilter';
import { GlobalSearchDetailsSsnClinicLink } from './GlobalSearchDetailsSsnClinicLink';
import { GlobalSearchDetailsTabAffiliationFilter } from './GlobalSearchDetailsTabAffiliationFilter';
import { GlobalSearchDetailsTabDescription } from './GlobalSearchDetailsTabDescription';
import { GlobalSearchDetailsTabDoctorWriteMeFilter } from './GlobalSearchDetailsTabDoctorWriteMeFilter';
import { GlobalSearchDetailsTabFiltersSection } from './GlobalSearchDetailsTabFiltersSection';
import { GlobalSearchDetailsTabIcon } from './GlobalSearchDetailsTabIcon';
import { GlobalSearchDetailsTabLabel } from './GlobalSearchDetailsTabLabel';
import { GlobalSearchDetailsTabOrderFilter } from './GlobalSearchDetailsTabOrderFilter';
import { GlobalSearchDetailsTabRefineSearchWrapper } from './GlobalSearchDetailsTabRefineSearchWrapper';
import { GlobalSearchDetailsTabWrapper } from './GlobalSearchDetailsTabWrapper';
import { GlobalSearchField } from './GlobalSearchField';
import { GlobalSearchListItemWrapper } from './GlobalSearchListItemWrapper';

export { GlobalSearchDetailsCardClinic } from './GlobalSearchDetailsCardClinic';

export { GlobalSearchDetailsCardClinicAvatar } from './GlobalSearchDetailsCardClinicAvatar';

export const GlobalSearchDetails = {
  PageWrapper: GlobalSearchDetailsPageWrapper,
  ResultSection: GlobalSearchDetailsResultSection,
  ResultTitle: GlobalSearchDetailsResultTitle,
  FiltersSection: GlobalSearchDetailsFiltersSection,
  FiltersSectionMobile: GlobalSearchDetailsFiltersSectionMobile,
  Tabs: GlobalSearchDetailsListTabs,
  TabWrapper: GlobalSearchDetailsTabWrapper,
  List: GlobalSearchDetailsList,
  ListWrapper: GlobalSearchDetailsListWrapper,
  ListItemWrapper: GlobalSearchListItemWrapper,
  FilterChips: GlobalSearchDetailsFilterChips,
  AffiliatedDoctorTab: GlobalSearchDetailsAffiliatedDoctorTab,
  CardProfileLink: GlobalSearchDetailsCardProfileLink,
  CardCv: GlobalSearchDetailsCardCv,
  CardDetailsWrapper: GlobalSearchDetailsCardDetailsWrapper,
  CardDoctor: GlobalSearchDetailsCardDoctor,
  CardDoctorAvatar: GlobalSearchDetailsCardDoctorAvatar,
  CardObject: GlobalSearchDetailsCardObject,
  CardServiceChip: GlobalSearchDetailsCardServiceChip,
  CardSphereOfInterest: GlobalSearchDetailsCardSphereOfInterest,
  CardWrapper: GlobalSearchDetailsCardWrapper,
  DoctorTab: GlobalSearchDetailsDoctorTab,
  ClinicsTab: GlobalSearchDetailsClinicsTab,
  FreeClinicsTab: GlobalSearchDetailsFreeClinicsTab,
  Filters: GlobalSearchDetailsFilters,
  FiltersModal: GlobalSearchDetailsFiltersModal,
  FilterFieldMobile: GlobalSearchDetailsFilterFieldMobile,
  NoResult: GlobalSearchDetailsNoResult,
  SpecificSearchFilter: GlobalSearchDetailsSpecificSearchFilter,
  TabLabel: GlobalSearchDetailsTabLabel,
  SearchField: GlobalSearchField,
  SsnClinicLink: GlobalSearchDetailsSsnClinicLink,
  TabIcon: GlobalSearchDetailsTabIcon,
  TabDescription: GlobalSearchDetailsTabDescription,
  TabOrderFilter: GlobalSearchDetailsTabOrderFilter,
  TabAffiliationFilter: GlobalSearchDetailsTabAffiliationFilter,
  TabFiltersSection: GlobalSearchDetailsTabFiltersSection,
  TabRefineSearchWrapper: GlobalSearchDetailsTabRefineSearchWrapper,
  TabDoctorWriteMeFilter: GlobalSearchDetailsTabDoctorWriteMeFilter,
  Accordion: GlobalSearchDetailsAccordion,
  Accordions: GlobalSearchDetailsAccordions,
  ContactsLabel: GlobalSearchDetailsContactsLabel,
  ScrollTopBottomButton: GlobalSearchDetailsScrollTopBottomButton,
};
