import { PersistedDataKeys } from '@lib/core';

import { ChatType, ProfileType } from '__generated__/types';
import { useLoginModelLoginLinks } from 'features/Login/model';
import { useRootModelPath } from 'features/Root/model';
import { persistedData } from 'lib/helpers/persistedData';
import { ROUTE_PATHS } from 'routes/constants';

type UseUnauthorizedUserChatMessageModelLoginHandler = () => (chatId?: string, chatType?: ChatType) => void;

export const useUnauthorizedUserChatMessageModelLoginHandler: UseUnauthorizedUserChatMessageModelLoginHandler = () => {
  const { getPath } = useRootModelPath();
  const loginHandler = useLoginModelLoginLinks();

  return (chatId, chatType) => {
    if (chatId) {
      persistedData.set(PersistedDataKeys.PriorProfileType, ProfileType.PATIENT);
    }

    const path = chatType === ChatType.ONE_TO_ONE ? ROUTE_PATHS.chatOneToOne : ROUTE_PATHS.chatClinic;
    const redirectTo = chatId ? getPath({ path, pathParams: { chatId } }) : '';

    loginHandler(
      redirectTo
        ? {
            searchObj: { redirectTo },
            redirectForApp: redirectTo,
          }
        : {}
    )();
  };
};
