import { PersistedDataKeys } from '@lib/core';
import {
  chatModel as chatModelBll,
  IClinicChatsItemParsed,
  IOneToOneChatsItemParsed,
  PatientCareTeam,
  useDashboardApiPatientCareTeamStatsLazyQuery,
  useDashboardApiPortalUserStatsLazyQuery,
  usePatientCareTeam,
} from '@lib/features-bll';
import { CareTeamIcon, InfoRequestIcon, ReminderIcon, RequestsIcon, SurveysIcon } from '@lib/icons';
import { useBreakpoints } from '@lib/react-components';
import { iif } from '@lib/utils';
import { useEffect, useImperativeHandle, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DashboardSubRoutes, PATIENT_DASHBOARD_TILES_LABELS } from '../config';
import {
  DashboardPatientSubRoutes,
  IDashboardTile,
  IDashboardUrlParams,
  IUseDashboardParams,
  PatientCounters,
  PatientCountersSubRoutesCounters,
} from '../types';

import { useComputeDefaultActiveItem } from './useComputeDefaultActiveItem';
import { useFormatDashboardActiveItemRoute } from './useFormatDashboardActiveItemRoute';

import { ChatOrderByField, ChatType, Maybe, OrderByType } from '__generated__/types';
import { CHATS_LISTING_CONFIGURATION, NUMBER_OF_CHAT_LIST_ROWS } from 'features/Chat/config';
import { useChatModelChatListingFilters, useChatModelChatSortPreference } from 'features/Chat/model';
import { IChatSortProps } from 'features/Chat/types';
import { useInfoModelInfoList } from 'features/Info';
import { IMemberCardUiCardProps } from 'features/MemberCard/ui';
import { usePortalConfiguration } from 'features/Portal';
import { useDashboardReminders } from 'features/Reminders/graphql/__generated__/DashboardReminders.query';
import { ReminderTableRow } from 'features/Reminders/ui/RemindersTable/types';
import { usePatientSurveyList } from 'features/Surveys/hooks';
import { IPatientSurveyListEntry } from 'features/Surveys/types';
import { useUserModelStore } from 'features/Users/model';
import { PaginatedRequestData } from 'graphql/types';
import { IUsePaginationReturnType } from 'lib/hooks/usePagination.hook';

interface IUsePatientDashboardReturn {
  dashboardLoading: boolean;
  tiles: IDashboardTile[];
  activeItem: DashboardSubRoutes;
  activeCount: Maybe<number>;
  totalCount: number;
  totalCountOneToOneChats: number;
  totalCountClinicChats: number;
  pinnedOneToOneChats: IOneToOneChatsItemParsed[];
  pinnedOneToOneChatsTotalCount: number;
  pinnedOneToOneChatsLoading: boolean;
  pinnedOneToOneChatsSortProps: IChatSortProps;
  isViewBoxPinnedOneToOneChatsVisible: boolean;
  loadMorePinnedOneToOneChats: VoidFunction;
  pinnedClinicChats: IClinicChatsItemParsed[];
  pinnedClinicChatsTotalCount: number;
  pinnedClinicChatsLoading: boolean;
  pinnedClinicChatsSortProps: IChatSortProps;
  isViewBoxPinnedClinicChatsVisible: boolean;
  loadMorePinnedClinicChats: VoidFunction;
  infos: ReturnType<typeof useInfoModelInfoList>['infoItems'];
  infoLoading: boolean;
  infoPaginationProps: ReturnType<typeof useInfoModelInfoList>['paginationProps'];
  infoSortProps: ReturnType<typeof useInfoModelInfoList>['sortProps'];
  surveys?: PaginatedRequestData<IPatientSurveyListEntry[]>;
  surveysLoading: boolean;
  surveysPagination: IUsePaginationReturnType;
  reminders: Maybe<ReminderTableRow[]>;
  remindersLoading: boolean;
  remindersRefetch: ReturnType<typeof useDashboardReminders>['refetch'];
  careTeam: IMemberCardUiCardProps[];
  careTeamLoading?: boolean;
}

export const usePatientDashboard = ({ dashboardRef }: IUseDashboardParams): IUsePatientDashboardReturn => {
  const { activeItem } = useParams<IDashboardUrlParams>();
  const configuration = usePortalConfiguration();
  const { activePatient } = useUserModelStore();
  const { isMobileView } = useBreakpoints();

  const [careTeam, setCareTeam] = useState<IMemberCardUiCardProps[]>([]);

  const [counters, setCounters] = useState<PatientCounters>({
    [DashboardSubRoutes.INFO]: 0,
    [DashboardSubRoutes.SURVEYS]: 0,
    [DashboardSubRoutes.REMINDERS]: 0,
    [DashboardSubRoutes.CHATS_ONE_TO_ONE]: 0,
    [DashboardSubRoutes.CHATS_CLINIC]: 0,
    [DashboardSubRoutes.CARE_TEAM]: 0,
  });

  useImperativeHandle(dashboardRef, () => ({
    getItemCount: item => counters[item as PatientCountersSubRoutesCounters],
  }));

  const [countersLoading, setCountersLoading] = useState(true);

  const [getPortalUserStats] = useDashboardApiPortalUserStatsLazyQuery();
  const [getPatientCareTeamStats] = useDashboardApiPatientCareTeamStatsLazyQuery({
    variables: {
      patientId: activePatient?.patient?.id || '',
    },
  });

  const { currentFilters: oneToOneChatsCurrentFilters } = useChatModelChatListingFilters({
    chatType: ChatType.ONE_TO_ONE,
  });
  const [oneToOneChatsSortPreference, setOneToOneChatsSortPreference] = useChatModelChatSortPreference({
    initialOrderBy: { field: ChatOrderByField.LAST_ITEM_TIMESTAMP, type: OrderByType.DESC },
    storageKey: PersistedDataKeys.OneToOneChatOrderBy,
  });
  const {
    pinnedOneToOneChats,
    pinnedOneToOneChatsLoading,
    totalCount: pinnedOneToOneChatsTotalCount,
    loadMore: loadMorePinnedOneToOneChats,
    sortBy: pinnedOneToOneChatsSortBy,
  } = chatModelBll.useOneToOneChatsPinned({
    skip: activeItem !== DashboardSubRoutes.CHATS_ONE_TO_ONE,
    numberOfRecords: NUMBER_OF_CHAT_LIST_ROWS,
    configuration: CHATS_LISTING_CONFIGURATION,
    initialFilters: oneToOneChatsCurrentFilters,
    initialOrderBy: oneToOneChatsSortPreference,
    afterOrderByChange: setOneToOneChatsSortPreference,
  });
  const pinnedOneToOneChatsSortProps: IChatSortProps = {
    sortBy: pinnedOneToOneChatsSortBy,
    sortPreference: oneToOneChatsSortPreference,
    sortable: false,
  };

  const { currentFilters: clinicChatsCurrentFilters } = useChatModelChatListingFilters({
    chatType: ChatType.CLINIC,
  });
  const [clinicChatsSortPreference, setClinicChatsSortPreference] = useChatModelChatSortPreference({
    initialOrderBy: { field: ChatOrderByField.LAST_ITEM_TIMESTAMP, type: OrderByType.DESC },
    storageKey: PersistedDataKeys.ClinicChatOrderBy,
  });
  const {
    pinnedClinicChats,
    pinnedClinicChatsLoading,
    totalCount: pinnedClinicChatsTotalCount,
    loadMore: loadMorePinnedClinicChats,
    sortBy: pinnedClinicChatsSortBy,
  } = chatModelBll.useClinicChatsPinned({
    skip: activeItem !== DashboardSubRoutes.CHATS_CLINIC,
    numberOfRecords: NUMBER_OF_CHAT_LIST_ROWS,
    configuration: CHATS_LISTING_CONFIGURATION,
    initialFilters: clinicChatsCurrentFilters,
    initialOrderBy: clinicChatsSortPreference,
    afterOrderByChange: setClinicChatsSortPreference,
  });
  const pinnedClinicChatsSortProps: IChatSortProps = {
    sortBy: pinnedClinicChatsSortBy,
    sortPreference: clinicChatsSortPreference,
    sortable: false,
  };

  const {
    infoItems: infos,
    totalCount: infoTotalCount,
    loading: infoLoading,
    paginationProps: infoPaginationProps,
    sortProps: infoSortProps,
  } = useInfoModelInfoList({
    isPinnedToHome: true,
    skip: activeItem !== DashboardSubRoutes.INFO,
  });

  const {
    surveyList: surveys,
    pagination: surveysPagination,
    loading: surveysLoading,
  } = usePatientSurveyList({
    includeHiddenInDashboard: false,
    skip: activeItem !== DashboardSubRoutes.SURVEYS,
  });

  const {
    data: remindersData,
    loading: remindersLoading,
    refetch: remindersRefetch,
  } = useDashboardReminders({
    skip: activeItem !== DashboardSubRoutes.REMINDERS,
  });

  const { data: careTeamData, loading: careTeamLoading } = usePatientCareTeam({
    skip: activeItem !== DashboardSubRoutes.CARE_TEAM,
    variables: {
      patientId: activePatient?.patient?.id || '',
    },
  });

  const formatDashboardActiveItemRoute = useFormatDashboardActiveItemRoute();

  const computeDefaultActiveItem = useComputeDefaultActiveItem({
    itemOrder: [
      DashboardSubRoutes.CHATS_ONE_TO_ONE,
      DashboardSubRoutes.CHATS_CLINIC,
      DashboardSubRoutes.INFO,
      DashboardSubRoutes.REMINDERS,
      DashboardSubRoutes.SURVEYS,
      DashboardSubRoutes.CARE_TEAM,
    ],
    fallback: DashboardSubRoutes.CHATS_ONE_TO_ONE,
  });

  const formatBaseTile = (subRoute: DashboardPatientSubRoutes): IDashboardTile => {
    if (subRoute === DashboardSubRoutes.CHATS) {
      const count = counters[DashboardSubRoutes.CHATS_ONE_TO_ONE] + counters[DashboardSubRoutes.CHATS_CLINIC];

      return {
        tile: subRoute,
        labelKey: PATIENT_DASHBOARD_TILES_LABELS[subRoute],
        count,
        selected:
          activeItem === DashboardSubRoutes.CHATS_ONE_TO_ONE ||
          activeItem === DashboardSubRoutes.CHATS_CLINIC ||
          activeItem === subRoute,
        routePath: formatDashboardActiveItemRoute(DashboardSubRoutes.CHATS_ONE_TO_ONE),
      };
    }

    if (subRoute === DashboardSubRoutes.CHATS_ONE_TO_ONE) {
      return {
        tile: subRoute,
        labelKey:
          PATIENT_DASHBOARD_TILES_LABELS[!configuration?.isClinicsEnabled ? DashboardSubRoutes.CHATS : subRoute],
        count: counters[subRoute],
        selected: activeItem === subRoute || activeItem === DashboardSubRoutes.CHATS,
        routePath: formatDashboardActiveItemRoute(subRoute),
      };
    }

    if (subRoute === DashboardSubRoutes.CHATS_CLINIC) {
      return {
        tile: subRoute,
        labelKey: PATIENT_DASHBOARD_TILES_LABELS[subRoute],
        count: counters[subRoute],
        selected: activeItem === subRoute,
        routePath: formatDashboardActiveItemRoute(subRoute),
      };
    }

    return {
      tile: subRoute,
      labelKey: PATIENT_DASHBOARD_TILES_LABELS[subRoute],
      count: counters[subRoute],
      selected: activeItem === subRoute,
      routePath: formatDashboardActiveItemRoute(subRoute),
    };
  };

  const tiles = [
    ...iif(
      !!configuration?.isPatientSelfRegistrationEnabled,
      configuration?.isClinicsEnabled
        ? {
            ...formatBaseTile(DashboardSubRoutes.CHATS),
            icon: RequestsIcon,
            subHeaders: [
              { ...formatBaseTile(DashboardSubRoutes.CHATS_ONE_TO_ONE) },
              { ...formatBaseTile(DashboardSubRoutes.CHATS_CLINIC) },
            ],
          }
        : {
            ...formatBaseTile(DashboardSubRoutes.CHATS_ONE_TO_ONE),
            icon: RequestsIcon,
          }
    ),

    {
      ...formatBaseTile(DashboardSubRoutes.INFO),
      icon: InfoRequestIcon,
    },
    ...iif(!!configuration?.hasAvailableSurveys, {
      ...formatBaseTile(DashboardSubRoutes.SURVEYS),
      icon: SurveysIcon,
    }),
    {
      ...formatBaseTile(DashboardSubRoutes.REMINDERS),
      icon: ReminderIcon,
    },
    {
      ...formatBaseTile(DashboardSubRoutes.CARE_TEAM),
      icon: CareTeamIcon,
    },
  ];

  const totalCount = tiles.reduce((sum, tabData) => sum + tabData.count, 0);

  const enrichCareTeam = (patientCareTeam: PatientCareTeam['patientCareTeam']): void => {
    setCareTeam(
      patientCareTeam.map(patientCareTeamMember => ({
        ...patientCareTeamMember,
        member: patientCareTeamMember.institutionUser,
      }))
    );
  };

  const isViewBoxPinnedClinicChatsVisible = pinnedClinicChats.length >= NUMBER_OF_CHAT_LIST_ROWS;
  const isViewBoxPinnedOneToOneChatsVisible = pinnedOneToOneChats.length >= NUMBER_OF_CHAT_LIST_ROWS;

  useEffect(() => {
    const handleInitialCounters = async (): Promise<void> => {
      const { data: userStats } = await getPortalUserStats();
      const { data: careTeamStats } = await getPatientCareTeamStats();

      const initialCounters = {
        [DashboardSubRoutes.CHATS_ONE_TO_ONE]: userStats?.portalUserStats?.chatStats.oneToOnePinnedToHomeCount ?? 0,
        [DashboardSubRoutes.CHATS_CLINIC]: userStats?.portalUserStats?.chatStats.clinicPinnedToHomeCount ?? 0,
        [DashboardSubRoutes.SURVEYS]: surveys?.totalCount ?? userStats?.patientSurveys?.totalCount ?? 0,
        [DashboardSubRoutes.REMINDERS]:
          remindersData?.dashboardReminders?.length ?? userStats?.dashboardReminders?.length ?? 0,
        [DashboardSubRoutes.CARE_TEAM]: careTeamStats?.patientCareTeam.length ?? 0,
        [DashboardSubRoutes.INFO]: infoTotalCount ?? userStats?.infoThreads?.totalCount ?? 0,
      };

      setCounters(initialCounters);

      if (!activeItem && !isMobileView) {
        computeDefaultActiveItem(initialCounters);
      }

      setCountersLoading(false);
    };

    handleInitialCounters();
  }, []);

  useEffect(() => {
    if (!activeItem && !isMobileView) {
      computeDefaultActiveItem(counters);
    }
  }, [activeItem]);

  useEffect(() => {
    if (careTeamData?.patientCareTeam) {
      enrichCareTeam(careTeamData.patientCareTeam);
    }
  }, [careTeamData?.patientCareTeam]);

  return {
    dashboardLoading: countersLoading,
    activeItem,
    activeCount:
      activeItem && activeItem !== DashboardSubRoutes.CHATS && activeItem !== DashboardSubRoutes.INTRAMED
        ? counters[activeItem]
        : null,
    tiles,
    totalCount,
    totalCountOneToOneChats: counters[DashboardSubRoutes.CHATS_ONE_TO_ONE],
    totalCountClinicChats: counters[DashboardSubRoutes.CHATS_CLINIC],
    pinnedOneToOneChats,
    pinnedOneToOneChatsTotalCount,
    pinnedOneToOneChatsLoading,
    pinnedOneToOneChatsSortProps,
    isViewBoxPinnedClinicChatsVisible,
    loadMorePinnedOneToOneChats,
    pinnedClinicChats,
    pinnedClinicChatsTotalCount,
    pinnedClinicChatsLoading,
    pinnedClinicChatsSortProps,
    isViewBoxPinnedOneToOneChatsVisible,
    loadMorePinnedClinicChats,
    infos,
    infoLoading,
    infoPaginationProps,
    infoSortProps,
    surveys,
    surveysLoading,
    surveysPagination,
    reminders: remindersData?.dashboardReminders ?? null,
    remindersLoading,
    remindersRefetch,
    careTeam,
    careTeamLoading,
  };
};
