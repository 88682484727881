import { MaterialSymbolsIcon, NotificationCircleFilledIcon, NotificationIcon } from '@lib/icons';
import { FlexBox } from '@lib/react-components';
import { useTheme } from '@mui/material';
import { matchPath, useHistory } from 'react-router-dom';

import { AnnouncementBadge } from 'features/Announcements';
import { useRootModelPath } from 'features/Root/model';
import { ROUTE_PATHS } from 'routes/constants';

export const AnnouncementIcon = (): JSX.Element => {
  const history = useHistory();
  const theme = useTheme();
  const { makeGoToPath, getPath } = useRootModelPath();
  const isAnnouncementsPath = matchPath(history.location.pathname, getPath({ path: ROUTE_PATHS.announcements }));

  const cursorValue = isAnnouncementsPath ? 'default' : 'pointer';

  const onClick: VoidFunction = makeGoToPath({ path: ROUTE_PATHS.announcements });

  return (
    <FlexBox
      position="relative"
      alignItems="center"
      justifyContent="center"
      sx={{ cursor: cursorValue }}
      borderRadius="50%"
      onClick={onClick}
      mx={`${isAnnouncementsPath ? 6 : 10}px`}
    >
      <AnnouncementBadge top={0} right={1} visible={!isAnnouncementsPath}>
        <MaterialSymbolsIcon
          fontSize={isAnnouncementsPath ? 36 : 28}
          cursor={cursorValue}
          icon={
            isAnnouncementsPath ? (
              <NotificationCircleFilledIcon fill={theme.palette.action.disabled} />
            ) : (
              <NotificationIcon fontWeight="300" />
            )
          }
          hover={
            isAnnouncementsPath
              ? undefined
              : {
                  fill: theme.palette.black.main,
                }
          }
        />
      </AnnouncementBadge>
    </FlexBox>
  );
};
