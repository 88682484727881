import { useDoctorSurveys } from 'features/Surveys/graphql/__generated__/DoctorSurveys.query';
import { IDoctorSurveyListEntry } from 'features/Surveys/types';
import { PaginatedRequestData } from 'graphql/types';
import { IUsePaginationReturnType, usePagination } from 'lib/hooks/usePagination.hook';

type UseDoctorSurveyListReturnType = {
  surveyList?: PaginatedRequestData<IDoctorSurveyListEntry[]>;
  pagination: IUsePaginationReturnType;
  loading: boolean;
};

type UseDoctorSurveyListProps = {
  includeHiddenInDashboard: boolean;
  skip?: boolean;
};

type UseDoctorSurveyList = (props: UseDoctorSurveyListProps) => UseDoctorSurveyListReturnType;

// TODO: NP-6647 - remove this ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const useDoctorSurveyList: UseDoctorSurveyList = ({ includeHiddenInDashboard, skip }) => {
  const pagination = usePagination();
  const { data, loading } = useDoctorSurveys({
    variables: {
      includeHiddenInDashboard,
      pagination: pagination.paginationForRequest,
    },
    skip,
  });

  const surveyList = data?.doctorSurveys;

  return {
    surveyList,
    pagination,
    loading,
  };
};
