import { useBreakpoints } from '@lib/react-components';
import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { usePortalSlug } from 'features/Portal';
import { unauthorizedRoutes } from 'routes/config';
import { ROUTE_PATHS } from 'routes/constants';

type UseBodyClassNameEffect = (className: string) => void;

export const useRootModelBodyClassNameEffect: UseBodyClassNameEffect = className => {
  const portalSlug = usePortalSlug();
  const { pathname } = useLocation();
  const { isMobileView } = useBreakpoints();

  const isUnauthorizedRoute = unauthorizedRoutes
    .filter(({ path }) => path !== ROUTE_PATHS.assistedSearch)
    .some(({ path }) => !!matchPath(pathname, `/${portalSlug}${path}`));

  useEffect(() => {
    if (isMobileView && isUnauthorizedRoute) {
      document.body.classList.add(className);
    } else {
      document.body.classList.remove(className);
    }
  }, [isMobileView, isUnauthorizedRoute]);
};
