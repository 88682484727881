import { usePatientSurveys } from 'features/Surveys/graphql/__generated__/PatientSurveys.query';
import { IPatientSurveyListEntry } from 'features/Surveys/types';
import { PaginatedRequestData } from 'graphql/types';
import { IUsePaginationReturnType, usePagination } from 'lib/hooks/usePagination.hook';

type UsePatientSurveyListReturnType = {
  surveyList?: PaginatedRequestData<IPatientSurveyListEntry[]>;
  pagination: IUsePaginationReturnType;
  loading: boolean;
};

type UsePatientSurveyListProps = {
  includeHiddenInDashboard: boolean;
  skip?: boolean;
};

type UsePatientSurveyList = (props: UsePatientSurveyListProps) => UsePatientSurveyListReturnType;

export const usePatientSurveyList: UsePatientSurveyList = ({ includeHiddenInDashboard, skip }) => {
  const pagination = usePagination();
  const { data, loading } = usePatientSurveys({
    variables: {
      includeHiddenInDashboard,
      pagination: pagination.paginationForRequest,
    },
    skip,
  });
  const surveyList = data?.patientSurveys;

  return {
    surveyList,
    pagination,
    loading,
  };
};
