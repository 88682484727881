import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import { SurveyPortalUserModel } from './SurveyPortalUserModel.fragment';
import { UsersApiGenericUserModel } from '../../../../../../mobile/node_modules/@lib/features-bll/src/features/Users/api/graphql/__generated__/UsersApiGenericUserModel.fragment';
import { SurveyTitleModel } from './SurveyTitleModel.fragment';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type DoctorSurveysVariables = Types.Exact<{
  includeHiddenInDashboard: Types.Scalars['Boolean']['input'];
  pagination?: Types.InputMaybe<Types.PaginationModel>;
}>;


export type DoctorSurveys = { __typename?: 'Query', doctorSurveys: { __typename?: 'PaginatedDoctorSurveyListEntryModelResponse', totalCount: number, items: Array<{ __typename?: 'DoctorSurveyListEntryModel', latestAnswerDate?: string | null, recipientPortalUser: { __typename?: 'PortalUserModel', id: string, profileType: Types.ProfileType, institutionUser: { __typename?: 'InstitutionUserModel', id: string, profileType: Types.ProfileType, doctor?: { __typename?: 'DoctorModel', sphereOfInterest: Array<string>, specializations: Array<{ __typename?: 'DoctorSpecializationModel', type: Types.Specialization }> } | null, user: { __typename?: 'UserModel', id: string, email?: string | null, firstName: string, lastName: string, phoneNumber?: string | null, fiscalCode?: string | null, gender: Types.Gender, birthDate: string, isOtpActive: boolean, createdAt: string, patient?: { __typename?: 'PatientModel', id: string, phoneNumber?: string | null, email?: string | null, portalUser?: { __typename?: 'PortalUserModel', id: string } | null, privacyAcceptance: { __typename?: 'PatientPrivacyAcceptance', ehrConsensus: boolean } } | null, birthAddress: { __typename?: 'BirthAddressModel', fullAddress: string, googlePlaceId: string }, currentResidenceAddress?: { __typename?: 'ResidenceAddressModel', googlePlaceId: string, fullAddress: string } | null } } }, survey: { __typename?: 'SurveyModel', id: string, patientCanAnswer: boolean, title: string } }> } };


export const DoctorSurveysDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"DoctorSurveys"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"includeHiddenInDashboard"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"pagination"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"PaginationModel"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"doctorSurveys"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"includeHiddenInDashboard"},"value":{"kind":"Variable","name":{"kind":"Name","value":"includeHiddenInDashboard"}}},{"kind":"Argument","name":{"kind":"Name","value":"pagination"},"value":{"kind":"Variable","name":{"kind":"Name","value":"pagination"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"items"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"recipientPortalUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SurveyPortalUserModel"}}]}},{"kind":"Field","name":{"kind":"Name","value":"latestAnswerDate"}},{"kind":"Field","name":{"kind":"Name","value":"survey"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SurveyTitleModel"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"totalCount"}}]}}]}},...SurveyPortalUserModel.definitions,...UsersApiGenericUserModel.definitions,...SurveyTitleModel.definitions]} as unknown as DocumentNode;

/**
 * __useDoctorSurveys__
 *
 * To run a query within a React component, call `useDoctorSurveys` and pass it any options that fit your needs.
 * When your component renders, `useDoctorSurveys` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorSurveys({
 *   variables: {
 *      includeHiddenInDashboard: // value for 'includeHiddenInDashboard'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useDoctorSurveys(baseOptions: ApolloReactHooks.QueryHookOptions<DoctorSurveys, DoctorSurveysVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<DoctorSurveys, DoctorSurveysVariables>(DoctorSurveysDocument, options);
      }
export function useDoctorSurveysLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DoctorSurveys, DoctorSurveysVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<DoctorSurveys, DoctorSurveysVariables>(DoctorSurveysDocument, options);
        }
export type DoctorSurveysHookResult = ReturnType<typeof useDoctorSurveys>;
export type DoctorSurveysLazyQueryHookResult = ReturnType<typeof useDoctorSurveysLazyQuery>;
export type DoctorSurveysQueryResult = Apollo.QueryResult<DoctorSurveys, DoctorSurveysVariables>;