import { REGISTRATION_ROUTES_TYPES } from 'lib/constants/registration';

const UNAUTHORIZED_ROUTES = {
  loginByPortal: '/login',
  gsdSsoSignIn: '/gsd/sso/sign-in',
  gsdSsoRedirect: '/gsd/sso/redirect',
  ssoLogin: '/gsd/sso/login',
  forgotPasswordByPortal: '/forgot_password',
  forgotPasswordConfirmByPortal: '/forgot_password/confirm',
  multipleRoleRegister: '/register_choose_role',
  registrationByTypeAndPortal: `/registration/:type(${REGISTRATION_ROUTES_TYPES.DOCTOR}|${REGISTRATION_ROUTES_TYPES.PATIENT}|${REGISTRATION_ROUTES_TYPES.SECRETARY})`,
  consentAcceptance: '/consent/accept/:consentId',
  inviteById: '/invite/:inviteId',
  acceptPatientInvite: '/patient-invite/accept/:patientInviteId',
  userClaimAcceptedPatientRegistration: '/user-claim/:token',
  userClaimSucceedPatientRegistration: '/user-claim-created-successfully',
  draftDirectMessageConfirmation: '/draft-direct-message-confirmation',
  downloadProApp: '/download-pro-app',
  downloadPatientApp: '/download-patient-app',
  assistedSearch: '/assisted-search',
  assistedSearchMessageConfirmation: '/assisted-search-message-confirmation',
  inAppBrowser: '/in-app-browser',
} as const;

export const UNAUTHORIZED_ROUTES_LIST = Object.values(UNAUTHORIZED_ROUTES);

const AUTHORIZED_ROUTES = {
  dashboard: '/dashboard',
  dashboardItem: '/dashboard/:activeItem',
  dashboardCommon: '/dashboard/:activeItem?',
  chatsOneToOneList: '/chats/one-to-one',
  chatsClinicList: '/chats/clinic',
  chatCreation: '/chats/create',
  chatOneToOne: '/chats/one-to-one/:chatId',
  chatClinic: '/chats/clinic/:chatId',
  settings: '/settings',
  settingsTermsAndConditions: '/settings/terms_and_conditions',
  settingsServiceManagement: '/settings/service_management',
  settingsAffiliations: '/settings/affiliations',
  settingsAffiliationById: '/settings/affiliations/:affiliationId',
  equipesList: '/equipes',
  equipesCreate: '/equipes/create',
  equipesById: '/equipes/:equipeId',
  equipesAddMember: '/addmember',
  equipesEditById: '/equipes/:equipeId/addmember',
  calendar: '/calendar',
  patientsList: '/patients',
  patientsSelf: '/patients/self/:tabType',
  patientsSelfPreselectedSection: '/patients/self/:tabType/:section',
  patientsCreate: '/patients/create',
  patientsNewCreate: '/patients/create-new',
  patientsById: '/patients/:patientId/:tabType',
  patientsByIdPreselectedSection: '/patients/:patientId/:tabType/:section',
  patientCancelledFiles: '/cancelled_files',
  patientsByIdCancelledFiles: '/cancelled_files/:patientId',
  patientAindoFileParameters: '/patients/:patientId/file-params/:fileId',
  intramedList: '/intramed',
  intramedCreate: '/intramed/create',
  intramedById: '/intramed/:threadId',
  invites: '/invites',
  inviteCreation: '/invites/create',
  inviteDetails: '/invites/:id',
  fileRedirect: '/file/redirect',
  videoMeetingRedirect: '/videomeeting/redirect',
  videoMeetingRedirectTo: '/videomeeting/redirect/:id',
  videoVisitRedirect: '/videovisit/redirect',
  videoVisitRedirectTo: '/videovisit/redirect/:id',
  searchByTabType: '/search/:activeTab?',
  search: '/search',
  requestSsnClinics: '/request/ssn-clinics',
  reservationClinic: '/reservation-clinic/:clinicId',
  freeClinic: '/free-clinic/:clinicId',
  clinic: '/clinic/:clinicId',
  doctor: '/doctor/:doctorSlug',
  affiliatedDoctor: '/affiliated-doctor/:doctorSlug',
  reservationClinicService: '/reservation-clinic/:clinicId/service/:serviceId',
  clinicService: '/clinic/:clinicId/service/:serviceId',
  doctorService: '/doctor/:doctorSlug/service/:serviceId',
  freeClinicService: '/free-clinic/:clinicId/service/:serviceId',
  affiliatedDoctorService: '/affiliated-doctor/:doctorSlug/service/:serviceId',
  carTNetworkList: '/cartnetwork',
  carTNetworkById: '/cartnetwork/:carTNetwork',
  carTNetworkInviteUser: '/cartnetwork/invites/user',
  consultationById: '/consultations/:requestId',
  payment: '/payment/:requestId',
  infoList: '/info',
  infoById: '/info/:infoId',
  accounting: '/accounting',
  accountingEntries: '/accounting/entries',
  reminders: '/reminders',
  reminder: '/reminders/:reminderId',
  patientCareTeam: '/patient/careteam',
  patientInvoices: '/patient/invoices',
  surveyFormPage: '/survey/:surveyId/fill',
  surveyList: '/surveys',
  surveyIssueCreation: '/survey/create',
  surveyDetails: '/surveys/:surveyId/:recipientId',
  clinics: '/clinics',
  portalDocuments: '/portal-documents',
  announcements: '/announcements',
  404: '/*',
} as const;

export const ROUTE_PATHS = {
  ...UNAUTHORIZED_ROUTES,
  ...AUTHORIZED_ROUTES,
};
