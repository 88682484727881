import { LogoutIcon, NotificationIcon, ProfileSettingsIcon, SupportIcon } from '@lib/icons';

import { UseProfileMenuItems } from './types';

import { useRootModelPath } from 'features/Root/model';
import { useUserModelStore } from 'features/Users/model';
import { i18n } from 'i18n';
import { DEFAULT_UNAUTHORIZED_ROUTE, ROUTE_PATHS } from 'routes/constants';

export const useProfileMenuItems: UseProfileMenuItems = ({ toggleSupportModalHandler, profileSwitcherRoleItems }) => {
  const { makeGoToPath, getPath } = useRootModelPath();
  const { activeProfile } = useUserModelStore();

  return [
    ...(profileSwitcherRoleItems || []),
    {
      isDividerLine: true,
    },
    ...(activeProfile?.hasPostRegister
      ? []
      : [
          {
            title: i18n.t('components.mobileMenuModalList.profile.notifications'),
            linkPath: getPath({ path: ROUTE_PATHS.announcements }),
            icon: NotificationIcon,
            withAnnouncement: true,
          },
        ]),
    {
      title: i18n.t('components.mobileMenuModalList.profile.settings'),
      linkPath: getPath({ path: ROUTE_PATHS.settings }),
      icon: ProfileSettingsIcon,
    },
    {
      title: i18n.t('components.mobileMenuModalList.profile.support'),
      icon: SupportIcon,
      onClickHandler: toggleSupportModalHandler,
    },
    {
      title: i18n.t('components.mobileMenuModalList.profile.signOut'),
      onClickHandler: makeGoToPath({ path: DEFAULT_UNAUTHORIZED_ROUTE }),
      icon: LogoutIcon,
    },
  ];
};
