import { useEffect } from 'react';

import { useIsMobileAppWebView } from './useIsMobileAppWebView';

export const useGTM: VoidFunction = () => {
  const isMobileAppWebView = useIsMobileAppWebView();

  const createScript = (id: string, scriptText: string): void => {
    const script = document.createElement('script');
    script.setAttribute('id', id);
    script.appendChild(document.createTextNode(scriptText));
    document.head.appendChild(script);
  };

  const noScriptGTM = (id: string, url: string): void => {
    const noscript = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    noscript.setAttribute('id', id);
    iframe.setAttribute('src', url);
    iframe.setAttribute('width', '0');
    iframe.setAttribute('height', '0');
    iframe.setAttribute('style', 'display:none;visibility:hidden');
    noscript.appendChild(iframe);

    const body = document.getElementsByTagName('body')[0];
    body.insertBefore(noscript, body.firstChild);
  };

  const googleTagManager: VoidFunction = () => {
    const id = 'google-tag-manager';
    const idGTM = 'GTM-MHQPZQ3G';
    const prodNoscriptUrl = `https://www.googletagmanager.com/ns.html?id=${idGTM}`;
    const testNoscriptUrl = `https://www.googletagmanager.com/ns.html?id=${idGTM}&gtm_auth=jIHITUxuBiw7q5N6SL9Ikg&gtm_preview=env-27&gtm_cookies_win=x`;
    const prodScriptText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${idGTM}')`;
    const testScriptText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=jIHITUxuBiw7q5N6SL9Ikg&gtm_preview=env-27&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${idGTM}');`;

    const isProdEnv = window.location.hostname === 'app.welmed.it';

    noScriptGTM(id, isProdEnv ? prodNoscriptUrl : testNoscriptUrl);
    createScript(id, isProdEnv ? prodScriptText : testScriptText);
  };

  useEffect(() => {
    if (!isMobileAppWebView) {
      googleTagManager();
    }
  }, [isMobileAppWebView]);
};
