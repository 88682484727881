import { BREAK_POINTS, fontWeight } from '@lib/react-components';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  checkboxesWrapper: {
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      marginBottom: 46,
    },
    marginBottom: 33,
    '& .MuiSvgIcon-root': {
      fontSize: 20,
    },
  },

  checkboxesWrapperNonPatient: {
    paddingLeft: 40,
  },

  checkboxWrapper: {
    marginBottom: 22,
  },

  checkboxLabel: {
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      fontSize: 16,
      lineHeight: '24px',
    },
    color: theme.palette.black.main,
    fontSize: 14,
    lineHeight: '150%',
    fontWeight: fontWeight.regular,
    marginBottom: 0,
  },

  documentLink: {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
  },

  title: {
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      lineHeight: '24px',
      fontWeight: fontWeight.semiBold,
    },
    color: theme.palette.black.main,
    fontSize: 18,
    lineHeight: '22px',
    fontWeight: fontWeight.regular,
    marginBottom: 7,
  },
}));
