import { FlexBox, zIndexValues } from '@lib/react-components';
import { Box } from '@mui/material';
import { ReactNode } from 'react';

import { useGetNumberOfUnreadAnnouncements } from 'features/Announcements/graphql/__generated__/GetNumberOfUnreadAnnouncements.query';
import { useUserModelStore } from 'features/Users/model';

interface IAnnouncementBadgeProps {
  top?: number;
  right?: number;
  size?: number;
  children: ReactNode;
  visible?: boolean;
}

export const AnnouncementBadge = ({
  children,
  top,
  right,
  size = 10,
  visible = true,
}: IAnnouncementBadgeProps): JSX.Element => {
  const { activeProfile } = useUserModelStore();
  const { data } = useGetNumberOfUnreadAnnouncements({
    fetchPolicy: 'cache-first',
    skip: activeProfile?.hasPostRegister,
  });

  return (
    <FlexBox position="relative">
      {!!data?.getNumberOfUnreadAnnouncements && visible && (
        <Box
          sx={{
            position: 'absolute',
            top,
            right,
            width: size,
            height: size,
            borderRadius: '50%',
            backgroundColor: 'red.main',
            zIndex: zIndexValues.defaultValue,
          }}
        />
      )}
      {children}
    </FlexBox>
  );
};
