import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import { ReminderModel } from '../../../../../../mobile/node_modules/@lib/features-bll/src/features/Reminders/api/__generated__/ReminderModel.fragment';
import { InstitutionUserModelForAvatar } from '../../../../../../mobile/node_modules/@lib/features-bll/src/features/Users/graphql/__generated__/InstitutionUserModelForAvatar.fragment';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type DashboardRemindersVariables = Types.Exact<{ [key: string]: never; }>;


export type DashboardReminders = { __typename?: 'Query', dashboardReminders: Array<{ __typename?: 'ReminderModel', id: string, title: string, description: string, recurrenceType: Types.ReminderRecurrenceType, emailNotificationTime: string, dateStart: string, dateEnd?: string | null, createdAt: string, deletedAt?: string | null, isVisible: boolean, isDeletable: boolean, isModifiable: boolean, isSelf: boolean, creatorInstitutionUser: { __typename?: 'InstitutionUserModel', id: string, user: { __typename?: 'UserModel', firstName: string, lastName: string } }, recipientInstitutionUser: { __typename?: 'InstitutionUserModel', id: string, user: { __typename?: 'UserModel', firstName: string, lastName: string } } }> };


export const DashboardRemindersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"DashboardReminders"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"dashboardReminders"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ReminderModel"}}]}}]}},...ReminderModel.definitions,...InstitutionUserModelForAvatar.definitions]} as unknown as DocumentNode;

/**
 * __useDashboardReminders__
 *
 * To run a query within a React component, call `useDashboardReminders` and pass it any options that fit your needs.
 * When your component renders, `useDashboardReminders` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardReminders({
 *   variables: {
 *   },
 * });
 */
export function useDashboardReminders(baseOptions?: ApolloReactHooks.QueryHookOptions<DashboardReminders, DashboardRemindersVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<DashboardReminders, DashboardRemindersVariables>(DashboardRemindersDocument, options);
      }
export function useDashboardRemindersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DashboardReminders, DashboardRemindersVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<DashboardReminders, DashboardRemindersVariables>(DashboardRemindersDocument, options);
        }
export type DashboardRemindersHookResult = ReturnType<typeof useDashboardReminders>;
export type DashboardRemindersLazyQueryHookResult = ReturnType<typeof useDashboardRemindersLazyQuery>;
export type DashboardRemindersQueryResult = Apollo.QueryResult<DashboardReminders, DashboardRemindersVariables>;